import React, { Component } from "react";
import { connect } from 'react-redux';
import classNames from "classnames";
import ClickOutside from '../../Widget/ClickOutside';
import fanoutClient from '../../../utils/FanoutClient';
import * as roomActions from "../../../actions/room_actions";
import KnockImg from "../../../../resources/assets/Knock.svg";
import KnockActiveImg from "../../../../resources/assets/Knock-Active.svg";
import { isMobile } from "../../../utils/text";

class KnockButtonBottomBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            check: false,
            knock: false,
            knockRid: null,
            isMobile: isMobile(),
            hover: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                modal: false
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { knock, modal } = this.state;
        const { knocks, getCallState, displayMessage, hideMenu } = this.props;

        if (Object.keys(knocks).length !== Object.keys(prevProps.knocks).length) {
            if (Object.keys(knocks).length) {
                let callState = getCallState();
                Object.keys(knocks).map((key) => {
                    if (knocks[key] && callState && knocks[key].uid === callState.uid && ((!knocks[key].payload) || (knocks[key].payload && !knocks[key].payload.isAnonymousGuest))) {
                        this.setState({
                            check: false,
                            knock: true,
                            knockRid: knocks[key].rid
                        }, () => {
                            if (displayMessage) {
                                displayMessage('You knocked to join the live stream', 2000);
                            }
                        });
                    }
                    return key;
                });
            } else if (knock) {
                this.setState({
                    knock: false,
                    knockRid: null
                }, () => {
                    if (displayMessage) {
                        displayMessage('No longer asking to join the live stream', 1000);
                    }
                });
            }
        }

        if(hideMenu && !modal && prevState.modal !== modal) {
            hideMenu();
        }
    }

    handleKnock() {
        const { knock, knockRid } = this.state;
        const { getCallState } = this.props;

        if (!knock && getCallState) {
            this.setState({
                check: true
            }, () => {
                fanoutClient.sendKnockRequest(getCallState());
                this.setState({ modal: false });
            });
        } else if (knock && knockRid && getCallState) {
            fanoutClient.sendKnockRevoked(knockRid, getCallState());
            this.setState({ modal: false });
        }
    }

    render() {
        const { modal, check, knock, hover, isMobile } = this.state;
        const { streaming, isAnonymous } = this.props;

        return (
            <li
                className={classNames("toggle-knock", { 'active': knock || modal }, { 'disable': check || isAnonymous || (!streaming && !knock) })}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="knock"
                    title="Knock"
                    onClick={() => {
                        this.setState({
                            modal: !modal
                        })
                    }}
                >
                    <img src={knock || modal || hover ? KnockActiveImg : KnockImg} />
                    <div>
                        <span>Knock</span>
                    </div>
                </a>
                {modal ?
                    <div className='hangup-modal-wrapper'>
                        {knock ?
                            <ClickOutside
                                className='hangup-modal big-modal'
                                clickOutside={() => this.setState({ modal: false })}
                            >
                                <div className='hangup-title'>Cancel Knock</div>
                                <div className='hangup-text'>Remove knock request?</div>
                                <button
                                    className='btn hangup-knock-remove'
                                    onClick={this.handleKnock.bind(this)}
                                >
                                    Remove
                                </button>
                                <button
                                    className='btn hangup-cancel-knock'
                                    onClick={() => {
                                        this.setState({
                                            modal: false
                                        })
                                    }}
                                >
                                    Cancel
                                </button>
                            </ClickOutside>
                            :
                            <ClickOutside
                                className='hangup-modal big-modal'
                                clickOutside={() => this.setState({ modal: false })}
                            >
                                <div className='hangup-title'>Confirm Knock</div>
                                <div className='hangup-text'>Knocking tells the host that you’d like to join the stream. If the host lets you in, everyone will be able to see you on camera and hear you via your microphone, so be ready!</div>
                                <button
                                    className='btn hangup-knock'
                                    onClick={this.handleKnock.bind(this)}
                                >
                                    Knock
                                </button>
                                <button
                                    className='btn hangup-cancel-knock'
                                    onClick={() => {
                                        this.setState({
                                            modal: false
                                        })
                                    }}
                                >
                                    Cancel
                                </button>
                            </ClickOutside>
                        }
                    </div>
                    : null}
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        knocks: state.knocks,
        streaming: state.room.streaming
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayMessage: (message, timer) => {
            dispatch(roomActions.displayMessage({ message: message, timer: timer }));
        }
    };
};

const KnockContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(KnockButtonBottomBar);

export default KnockContainer;
