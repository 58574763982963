import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { push as nativePush } from 'connected-react-router';
import DateTime from 'react-datetime';
import momentZone from "moment-timezone";
import Cropper from 'react-easy-crop';
import firebaseClient from '../../utils/FirebaseClient';
import {
    prices,
    contents,
    numTickets,
    getQueryVariable,
    stringToBoolean,
    setDefaultBanner,
    getDefaultBanner,
    saveUnsaveBanner
} from '../../utils/text';
import { strings } from '../../translations/localizedStrings';
import { Defines } from '../../utils/FanoutDefines';
import { WithContext as ReactTags } from 'react-tag-input';
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, ContentState } from 'draft-js';
import deleteIcon from '../../../resources/assets/delete.svg';
import uploadIcon from '../../../resources/assets/upload.svg';
import linkIcon from '../../../resources/assets/link5.svg';
import zoomIn from '../../../resources/assets/mock-zoom-in.png';
import zoomOut from '../../../resources/assets/mock-zoom-out.png';
import speaker from '../../../resources/assets/mock-speaker.png';
import adParticipants from '../../../resources/assets/mock-ad+participant.png';
import Button from '../Widget/Button';
import Preview from '../Widget/Preview';

const KeyCodes = {
    comma: 188,
    enter: 13,
    tab: 9,
    space: 32,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab, KeyCodes.space];

class Calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postId: '',
            title: '',
            description: '',
            editorState: EditorState.createEmpty(),
            visibility: 'public',
            website: process.env.website ? process.env.website : '',
            version: process.env.version ? process.env.version : '',
            category: process.env.build && process.env.build === 'nfl-aa' ? 'PROFOOTBALL' : 'SPORTS',
            defaultLayout: Defines.RecordingLayout.AdParticipants,
            labels: Defines.Labels.Default,
            banners: [],
            bannerImg: '',
            bannerLink: '',
            bannerId: '',
            addBannerImgDisabled: false,
            tvRating: false,
            contentLabels: [],
            coHostShow: false,
            coHostInput: '',
            guestShow: false,
            guestInput: '',
            moderatorsShow: false,
            moderatorsInput: '',
            autoVideo: false,
            muteAudio: false,
            estDuration: 60,
            estDurationList: [
                { value: 15, text: '15+ mins' },
                { value: 30, text: '30+ mins' },
                { value: 45, text: '45+ mins' },
                { value: 60, text: '60+ mins' },
                { value: 90, text: '90+ mins' },
                { value: 120, text: '120+ mins' },
                { value: 180, text: '180+ mins' }
            ],
            timeZone: momentZone.tz.guess(),
            tipsShow: process.env.build && process.env.build === 'nfl-aa' ? false : true,
            price: process.env.build && process.env.build === 'nfl-aa' ? 0 : 4.99,
            tipsValue: process.env.build && process.env.build === 'nfl-aa' ? 0 : 4.99,
            numTicket: 500,
            watchLater: false,
            archivePrice: 0,
            expireTime: '6 M',
            stopDate: '',
            customCoverShow: false,
            customCover: '',
            defaultCover: null,
            cohosts: [],
            guestSpeakers: [],
            moderators: [],
            oldCohosts: [],
            oldGuestSpeakers: [],
            oldModerators: [],
            cohostsSuggestions: [],
            guestSpeakersSuggestions: [],
            moderatorsSuggestions: [],
            vanityId: '',
            showVanityForm: false,
            noCoHostMsg: '',
            noGuestMsg: '',
            noModeratorMsg: '',
            update: false,
            copyEvent: false,
            scheduling: false,
            startDT: this.getDefaultTime(),
            featured: false,
            sharedLink: null,
            privileges: null,
            addFileDisabled: false,
            error: null,
            eventCardImage: null,
            addEventCardImageDisabled: false,
            eventSquareImage: null,
            originalSquareImage: null,
            horizontalEventPageImage: null,
            addHorizontalEventPageImageDisabled: false,
            verticalEventPageImage: null,
            addVerticalEventPageImageDisabled: false,
            croppedAreaPixels: null,
            croppedForm: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 16 / 9,
            imgUrl: null,
            cropView: false,
            cropType: null,
            loadingData: false,
            previewImage: null,
            previewTitle: ''
        }

        this.buttonRef = React.createRef();
        this.addCardRef = React.createRef();
        this.addHorizontalRef = React.createRef();
        this.addVerticalRef = React.createRef();
        this.addBannerImgRef = React.createRef();
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.validDate = this.validDate.bind(this);
        this.cancelCrop = this.cancelCrop.bind(this);
        this.saveCropImage = this.saveCropImage.bind(this);
        this.handleChangeLayout = this.handleChangeLayout.bind(this);
        this.handleChangeDefaultBanner = this.handleChangeDefaultBanner.bind(this);
        this.handleChangeLabels = this.handleChangeLabels.bind(this);
        this.handleAddBanner = this.handleAddBanner.bind(this);
        this.handleDeleteBanner = this.handleDeleteBanner.bind(this);
        this.handleChangeVisibility = this.handleChangeVisibility.bind(this);
    }

    componentDidMount() {
        moment.tz.setDefault();
        this.getEventItem.call(this);
        this.checkGodMode();
        this.setDefaultsValue();
        this.setAmbassadorValue();
        firebaseClient.getUserHostingPrivileges().then((data) => {
            const { visibility } = this.state;
            this.setState({
                privileges: data,
                watchLater: visibility && visibility === 'private' ? false : data && data.record_show ? this.state.watchLater ? this.state.watchLater : false : false,
                price: data && data.min_price_event && data.min_price_event > this.state.price ? data.min_price_event : this.state.price,
            });
            if (data && data.session_limit) {
                let newList = [];
                if (data.session_limit >= 15) {
                    newList.push({ value: 15, text: '15+ mins' })
                }
                if (data.session_limit >= 30) {
                    newList.push({ value: 30, text: '30+ mins' })
                }
                if (data.session_limit >= 45) {
                    newList.push({ value: 45, text: '45+ mins' })
                }
                if (data.session_limit >= 60) {
                    newList.push({ value: 60, text: '60+ mins' })
                }
                if (data.session_limit >= 90) {
                    newList.push({ value: 90, text: '90+ mins' })
                }
                if (data.session_limit >= 120) {
                    newList.push({ value: 120, text: '120+ mins' })
                }
                if (data.session_limit >= 180) {
                    newList.push({ value: 180, text: '180+ mins' })
                }

                this.setState({
                    estDurationList: newList,
                    estDuration: data && data.session_limit && data.session_limit < this.state.estDuration ? data.session_limit : this.state.estDuration || 60
                });
            }
        });
        this.unlisten = this.props.history.listen((location, action) => {
            this.checkGodMode();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { user, defaults, ambassadors } = this.props;

        if (user && prevProps.user !== user) {
            this.checkGodMode();
            this.setAmbassadorValue();
        }

        if (defaults && prevProps.defaults !== defaults) {
            this.setDefaultsValue();
        }

        if (ambassadors && prevProps.ambassadors !== ambassadors) {
            this.setAmbassadorValue();
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    handleChangeVisibility(e) {
        this.setState({
            visibility: e.target.value
        }, () => {
            const { visibility } = this.state;

            if (visibility && visibility === 'private') {
                this.setState({
                    watchLater: false
                });
            }
        });
    }

    setAmbassadorValue() {
        const { user, ambassadors } = this.props;

        let isAmbassador = false;

        if (user && user.administrator) {
            isAmbassador = true;
        }

        if (!isAmbassador && ambassadors && ambassadors.length && user && user.uid) {
            isAmbassador = ambassadors.some(el => el.id && el.id === user.uid);
        }

        if (isAmbassador) {
            this.setState({
                defaultLayout: Defines.RecordingLayout.AdParticipants
            })
        }
    }

    setDefaultsValue() {
        const { copyEvent, postId } = this.state;
        const { defaults, user } = this.props;

        let newState = {};

        if (defaults && !postId && !copyEvent) {
            if (defaults.title !== undefined) newState.title = defaults.title;
            if (defaults.description !== undefined) newState.description = defaults.description;
            if (defaults.visibility !== undefined) newState.visibility = defaults.visibility;
            if (defaults.website !== undefined) newState.website = defaults.website;
            if (defaults.version !== undefined) newState.version = defaults.version;
            if (defaults.category !== undefined) newState.category = defaults.category;
            if (defaults.defaultLayout !== undefined) newState.defaultLayout = defaults.defaultLayout;
            if (defaults.labels !== undefined) newState.labels = defaults.labels;
            if (defaults.tvRating !== undefined) newState.tvRating = defaults.tvRating;
            if (defaults.estDuration !== undefined) newState.estDuration = defaults.estDuration;
            if (defaults.tipsShow !== undefined) newState.tipsShow = defaults.tipsShow;
            if (defaults.price !== undefined) newState.price = defaults.price;
            if (defaults.tipsValue !== undefined) newState.tipsValue = defaults.tipsValue;
            if (defaults.numTicket !== undefined) newState.numTicket = defaults.numTicket;
            if (defaults.watchLater !== undefined) newState.watchLater = defaults.visibility && defaults.visibility === 'private' ? false : defaults.watchLater;
            if (defaults.archivePrice !== undefined) newState.archivePrice = defaults.archivePrice;
            if (defaults.expireTime !== undefined) newState.expireTime = defaults.expireTime;
            if (defaults.customCoverShow !== undefined) newState.customCoverShow = defaults.customCoverShow;
            if (defaults.customCover !== undefined) newState.customCover = defaults.customCover;
            if (defaults.featured !== undefined) newState.featured = defaults.featured;

            let gm = stringToBoolean(getQueryVariable('gm'));

            if (user && user.administrator) {
                if (gm) {
                    delete newState.visibility;
                    delete newState.archivePrice;
                    delete newState.price;
                }
            }

            this.setState(newState);
        }
    }

    handleChangeLayout(event) {
        const value = event.target.value;

        if (value) {
            this.setState({
                defaultLayout: value
            });
        }
    }

    handleChangeDefaultBanner(event) {
        const { banners } = this.state;

        const id = event.target.value;

        if (id) {
            this.setState({
                banners: setDefaultBanner(banners, id)
            });
        }
    }

    handleChangeLabels(event) {
        const value = event.target.value;

        if (value) {
            this.setState({
                labels: value
            });
        }
    }

    checkGodMode() {
        const { timeZone } = this.state;
        const { user, defaults } = this.props;

        let gm = stringToBoolean(getQueryVariable('gm'));

        if (user && user.administrator) {
            if (gm) {
                this.setState({
                    visibility: 'private',
                    startDT: this.parseTime(moment().toDate().getTime(), timeZone),
                    archivePrice: 0,
                    price: 0
                });
            } else {
                this.setState({
                    visibility: defaults && defaults.visibility ? defaults.visibility : 'public',
                    startDT: this.getDefaultTime(),
                    archivePrice: defaults && defaults.archivePrice ? defaults.archivePrice : 0,
                    price: defaults && defaults.price ? defaults.price : process.env.build && process.env.build === 'nfl-aa' ? 0 : 4.99
                });
            }
        }
    }

    getDefaultTime() {
        const start = moment();

        start.add(1, 'days').set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

        const dateTime = moment(start).tz(momentZone.tz.guess());

        return dateTime;
    }

    getEventItem() {
        const { privileges } = this.state;
        const { match } = this.props || {};

        if ((!match || !match.params || !match.params.streamId) && (!match || !match.params || !match.params.eventId))
            return;

        let eventId = match.params.eventId;
        let streamId = match.params.streamId;

        let isStream = eventId ? false : true;

        let copyEvent = false;

        if (match && match.params && match.params.operationType && window.atob(match.params.operationType) === "copyEvent")
            copyEvent = true;

        this.setState({ loadingData: true });

        // Check current user tickets for selected stream
        firebaseClient.getOne(eventId ? '/calendar' : '/posts', eventId ? eventId : streamId).then(result => {
            if (!result)
                return;

            let event = result.data;

            if (event && event.uid && this.props.user && this.props.user.uid && this.props.user.uid !== event.uid)
                return;

            let price = ((typeof event.price === 'string' || event.price instanceof String) ? parseFloat(event.price) : event.price)

            let description = event.description ? event.description : '';

            if (description && typeof description !== 'string') {
                description = convertFromRaw(event.description);
                description = EditorState.createWithContent(description);
            } else {
                description = EditorState.createWithContent(ContentState.createFromText(description))
            }

            this.setState({
                loadingData: false,
                postId: !copyEvent && event.id ? event.id : '',
                title: event.title,
                description: event.description,
                editorState: description,
                visibility: event.visibility || 'public',
                category: event.cat,
                defaultLayout: event.callParams && event.callParams.layoutParams && event.callParams.layoutParams.defaultLayout ? event.callParams.layoutParams.defaultLayout : event.callParams && event.callParams.layout ? event.callParams.layout : Defines.RecordingLayout.AdParticipants,
                labels: event.callParams && event.callParams.layoutParams && event.callParams.layoutParams.labels ? event.callParams.layoutParams.labels : Defines.Labels.Default,
                bannerImg: event.callParams && event.callParams.layoutParams && event.callParams.layoutParams.banners && getDefaultBanner(event.callParams.layoutParams.banners) && getDefaultBanner(event.callParams.layoutParams.banners).img ? getDefaultBanner(event.callParams.layoutParams.banners).img : null,
                bannerLink: event.callParams && event.callParams.layoutParams && event.callParams.layoutParams.banners && getDefaultBanner(event.callParams.layoutParams.banners) && getDefaultBanner(event.callParams.layoutParams.banners).link ? getDefaultBanner(event.callParams.layoutParams.banners).link : null,
                tvRating: event.tvRating && event.tvRating === '18+' ? true : false,
                contentLabels: event.contentLabels,
                coHostShow: event.cohosts && event.cohosts.length ? true : false,
                coHostInput: '',
                guestShow: event.guestSpeakers && event.guestSpeakers.length ? true : false,
                guestInput: '',
                moderatorsShow: event.moderators && event.moderators.length ? true : false,
                moderatorsInput: '',
                autoVideo: event.autoVideo,
                muteAudio: event.muteAudio,
                estDuration: privileges && privileges.session_limit && privileges.session_limit < event.estDuration ? privileges.session_limit : event.estDuration || 60,
                tipsShow: event.acceptTips,
                price: privileges && privileges.min_price_event && privileges.min_price_event > price ? privileges.min_price_event : price,
                tipsValue: ((typeof event.tipsValue === 'string' || event.tipsValue instanceof String) ? parseFloat(event.tipsValue) : event.tipsValue),
                numTicket: event.noOfTickets ? numTickets.includes(event.noOfTickets) ? event.noOfTickets : 500 : 500,
                watchLater: event.visibility && event.visibility === 'private' ? false : privileges ? privileges.record_show ? event.archiveEnabled ? event.archiveEnabled : false : false : event.archiveEnabled,
                archivePrice: ((typeof event.archivePrice === 'string' || event.archivePrice instanceof String) ? parseFloat(event.archivePrice) : event.archivePrice),
                expireTime: event.expireTime || '6 M',
                stopDate: event.archiveStopDate,
                customCoverShow: ((event.archiveCustomCover) || (event.branding && (event.branding.eventCardImage || event.branding.horizontalEventPageImage || event.branding.verticalEventPageImage || event.branding.eventSquareImage))) ? true : false,
                customCover: event.archiveCustomCover,
                defaultCover: event.archiveCustomCover && ((!event.branding) || (event.branding && !event.branding.eventCardImage && !event.branding.horizontalEventPageImage && !event.branding.verticalEventPageImage)) ? event.archiveCustomCover : null,
                eventCardImage: event.branding && event.branding.eventCardImage ? event.branding.eventCardImage : null,
                eventSquareImage: event.branding && event.branding.eventSquareImage ? event.branding.eventSquareImage : null,
                originalSquareImage: event.branding && event.branding.originalSquareImage ? event.branding.originalSquareImage : null,
                horizontalEventPageImage: event.branding && event.branding.horizontalEventPageImage ? event.branding.horizontalEventPageImage : null,
                verticalEventPageImage: event.branding && event.branding.verticalEventPageImage ? event.branding.verticalEventPageImage : null,
                update: !copyEvent,
                copyEvent: copyEvent,
                timeZone: event.timeZone,
                cohosts: event.cohosts ? event.cohosts : [],
                oldCohosts: event.cohosts ? event.cohosts : [],
                guestSpeakers: event.guestSpeakers ? event.guestSpeakers : [],
                oldGuestSpeakers: event.guestSpeakers ? event.guestSpeakers : [],
                moderators: event.moderators ? event.moderators : [],
                oldModerators: event.moderators ? event.moderators : [],
                isStream: isStream,
                startDT: copyEvent ? this.getDefaultTime() : this.parseTime(event.startDate, event.timeZone, !copyEvent),
                stopDT: this.parseTime(event.archiveStopDate, event.timeZone, !copyEvent),
                featured: event.featured && !copyEvent ? event.featured : false,
                sharedLink: event.sharedLink || null
            });
        });
    }

    parseTime(time, zone, edit = false) {
        let now = Date.now(), start = null;

        if ((time > now) || edit) {
            start = moment(time ? new Date(time) : Date.now());
        } else {
            start = moment(Date.now());
        }

        const remainder = 5 - (start.minute() % 5);
        let dateTime = null;

        if (remainder && remainder < 5) {
            dateTime = moment(start).add(remainder, "minutes").tz(zone ? zone : momentZone.tz.guess());
        } else {
            dateTime = moment(start).tz(zone ? zone : momentZone.tz.guess());
        }

        return dateTime;
    }

    handleAddBanner() {
        const { bannerId, bannerImg, bannerLink, banners } = this.state;

        let bannersArray = banners;

        if (bannerId) {
            bannersArray = banners.filter((item) => {
                if (item.id && item.id === id) {
                    let updateItem = {
                        id: id,
                        img: bannerImg,
                        link: bannerLink,
                        default: false
                    }

                    return updateItem;
                } else return item;
            })
        } else {
            bannersArray.push({
                id: uuidv4(),
                img: bannerImg,
                link: bannerLink,
                default: false
            });
        }

        this.setState({
            banners: setDefaultBanner(bannersArray),
            bannerImg: '',
            bannerLink: '',
            bannerId: ''
        });
    }

    handleDeleteBanner(id) {
        const { banners } = this.state;

        let bannersArray = banners;

        if (id) {
            bannersArray = banners.filter((item) => item && item.id !== id);

            this.setState({
                banners: setDefaultBanner(bannersArray)
            });
        }
    }

    handleChangeContentLabels(e) {

        let labels = this.state.contentLabels;

        if (labels.includes(e.target.value)) {
            labels = labels.filter(label => label !== e.target.value)
        }
        else {
            labels.push(e.target.value)
        }

        this.setState({
            contentLabels: labels,
        })
    }

    async getWidthAndHeight(file) {
        let p = new Promise(async (resolve, reject) => {
            if (file) {
                const img = await document.createElement('img');

                const objectURL = await URL.createObjectURL(file);

                img.onload = async function handleLoad() {
                    URL.revokeObjectURL(objectURL);
                    resolve({ width: img.width, height: img.height });
                };

                img.src = objectURL;
            } else resolve(null);
        })

        return p;
    }

    async handleSetCropImage(event, type) {
        const { user } = this.props;

        if (event.target.files && event.target.files[0] && user) {
            let aspectRation = 16 / 9, croppedForm = false;
            if (type) {
                switch (type) {
                    case 'event-card':
                        this.setState({
                            addEventCardImageDisabled: true
                        });
                        let dimensions = await this.getWidthAndHeight(event.target.files[0]);
                        console.log('dimensions', dimensions);
                        if (dimensions && dimensions.width && dimensions.width === 3000 && dimensions.height && dimensions.height === 3000) {
                            if (user && user.uid) {
                                let id = `${user.uid}/${uuidv4()}`;

                                await firebaseClient.addImageToStorage(event.target.files[0], 'event', id).then((res) => {
                                    this.setState({
                                        originalSquareImage: res
                                    }, () => {
                                        let customId = `${user.uid}/${uuidv4()}`;
                                        return firebaseClient.addImageToStorage(event.target.files[0], 'event', customId, 400).then((res) => {
                                            this.setState({
                                                eventSquareImage: res,
                                                eventCardImage: null,
                                                customCover: null,
                                                addEventCardImageDisabled: false
                                            });
                                        });
                                    });
                                });

                                return;
                            }
                            aspectRation = 1;
                            croppedForm = true;
                        } else if (dimensions && dimensions.width && dimensions.width >= 3000 && dimensions.height && dimensions.height >= 3000) {
                            aspectRation = 1;
                            croppedForm = true;
                        } else {
                            aspectRation = 4 / 3;
                            croppedForm = true;
                        }
                        break;
                    case 'horizontal-image':
                        this.setState({
                            addHorizontalEventPageImageDisabled: true
                        });
                        aspectRation = 16 / 9;
                        break;
                    case 'vertical-image':
                        this.setState({
                            addVerticalEventPageImageDisabled: true
                        });
                        aspectRation = 1 / 2;
                        break;
                    case 'banner':
                        this.setState({
                            addBannerImgDisabled: true
                        });
                        aspectRation = 8 / 1;
                        break;
                    default:
                        break;
                }
            }

            let img = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                this.setState({
                    imgUrl: reader.result,
                    cropView: true,
                    cropType: type,
                    aspect: aspectRation,
                    croppedForm: croppedForm
                });
            }

            if (img) {
                reader.readAsDataURL(img);
            }
        }
    }

    handleClickAddImage(type) {
        if (type) {
            switch (type) {
                case 'event-card':
                    if (this.addCardRef && this.addCardRef.current && this.addCardRef.current.click) {
                        this.addCardRef.current.value = null;
                        this.addCardRef.current.click();
                    }
                    break;
                case 'horizontal-image':
                    if (this.addHorizontalRef && this.addHorizontalRef.current && this.addHorizontalRef.current.click) {
                        this.addHorizontalRef.current.value = null;
                        this.addHorizontalRef.current.click();
                    }
                    break;
                case 'vertical-image':
                    if (this.addVerticalRef && this.addVerticalRef.current && this.addVerticalRef.current.click) {
                        this.addVerticalRef.current.value = null;
                        this.addVerticalRef.current.click();
                    }
                    break;
                case 'banner':
                    if (this.addBannerImgRef && this.addBannerImgRef.current && this.addBannerImgRef.current.click) {
                        this.addBannerImgRef.current.value = null;
                        this.addBannerImgRef.current.click();
                    }
                    break;
                default:
                    break;
            }
        }
    }

    createImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })
    }

    getRadianAngle(rotation) {
        return (rotation * Math.PI) / 180
    }

    rotateSize(width, height, rotation) {
        const rotRad = this.getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    async getCroppedImg(imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
        const image = await this.createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return null
        }

        const rotRad = this.getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        )

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0)

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(file)
            }, 'image/jpeg')
        })
    }

    saveCropImage() {
        const { imgUrl, croppedAreaPixels, cropType, aspect } = this.state;
        const { user } = this.props;

        this.setState({
            cropView: false
        }, async () => {
            if (user && user.uid) {
                let id = `${user.uid}/${uuidv4()}`;
                const croppedImage = await this.getCroppedImg(imgUrl, croppedAreaPixels);

                let type = cropType && cropType === 'banner' ? 'layout' : 'event';

                let maxWidthOrHeight = cropType && cropType === 'event-card' ? 400 : 0;

                firebaseClient.addImageToStorage(croppedImage, type, id, maxWidthOrHeight).then(async (res) => {
                    let newState = {
                        croppedAreaPixels: null,
                        croppedForm: false,
                        crop: { x: 0, y: 0 },
                        zoom: 1,
                        aspect: 16 / 9,
                        imgUrl: null,
                        cropType: null
                    }

                    if (cropType) {
                        switch (cropType) {
                            case 'event-card':
                                if (aspect && aspect === 1) {
                                    newState.eventSquareImage = res;
                                    newState.originalSquareImage = null;
                                    newState.eventCardImage = null;
                                    newState.customCover = null;

                                    let dimensions = await this.getWidthAndHeight(croppedImage);

                                    if (dimensions && dimensions.width && dimensions.width >= 3000 && dimensions.height && dimensions.height >= 3000) {
                                        let customId = `${user.uid}/${uuidv4()}`;

                                        await firebaseClient.addImageToStorage(croppedImage, type, customId, 3000).then((res) => {
                                            newState.originalSquareImage = res;
                                        });
                                    }
                                } else {
                                    newState.eventSquareImage = null;
                                    newState.originalSquareImage = null;
                                    newState.eventCardImage = res;
                                    newState.customCover = res;
                                }
                                newState.addEventCardImageDisabled = false;
                                break;
                            case 'horizontal-image':
                                newState.horizontalEventPageImage = res;
                                newState.addHorizontalEventPageImageDisabled = false;
                                break;
                            case 'vertical-image':
                                newState.verticalEventPageImage = res;
                                newState.addVerticalEventPageImageDisabled = false;
                                break;
                            case 'banner':
                                newState.bannerImg = res;
                                newState.addBannerImgDisabled = false;
                                break;
                            default:
                                break;
                        }
                    }

                    this.setState(newState);
                });
            }
        });
    }

    cancelCrop() {
        this.setState({
            croppedAreaPixels: null,
            croppedForm: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 16 / 9,
            imgUrl: null,
            cropView: false,
            cropType: null,
            addEventCardImageDisabled: false,
            addHorizontalEventPageImageDisabled: false,
            addVerticalEventPageImageDisabled: false,
            addBannerImgDisabled: false
        });
    }

    getBrandingData() {
        const { eventCardImage, eventSquareImage, originalSquareImage, horizontalEventPageImage, verticalEventPageImage } = this.state;

        let data = {
            eventCardImage,
            eventSquareImage,
            originalSquareImage,
            horizontalEventPageImage,
            verticalEventPageImage
        }

        return data;
    }

    getCallParams() {
        const { defaultLayout, labels, bannerImg, bannerLink } = this.state;
        const { defaults } = this.props;

        let data = {
            layoutParams: {
                banners: firebaseClient.ifAdminOrAmbassador() ? saveUnsaveBanner([], bannerImg, bannerLink) : this.getRTDefaultBanner(),
                labels,
                defaultLayout: firebaseClient.ifAdminOrAmbassador() ? defaultLayout : defaults && defaults.defaultLayout ? defaults.defaultLayout : defaultLayout,
                layoutSettings: firebaseClient.ifAdminOrAmbassador()
            }
        }

        return data;
    }

    getRTDefaultBanner() {
        const { defaults } = this.props;

        let retVal = [];

        if (defaults && defaults.banners) {
            try {
                retVal = JSON.parse(defaults.banners);
            } catch (e) {
                console.error('getRTDefaultBanner', e)
            }
        }

        return retVal;
    }

    handleAdd() {
        const {
            postId,
            visibility,
            website,
            version,
            title,
            estDuration,
            category,
            description,
            editorState,
            tvRating,
            contentLabels,
            numTicket,
            price,
            muteAudio,
            autoVideo,
            tipsShow,
            tipsValue,
            watchLater,
            customCover,
            customCoverShow,
            cohosts,
            guestSpeakers,
            moderators,
            stopDate,
            expireTime,
            update,
            timeZone,
            archivePrice,
            isStream,
            startDT,
            stopDT,
            featured,
            sharedLink,
            coHostShow,
            guestShow,
            moderatorsShow,
        } = this.state;

        const { user } = this.props;

        let event = {};

        let hostData = {
            id: user && user.uid ? user.uid : null,
            text: user && user.email ? user.email : null,
            email: user && user.email ? user.email : null,
            photoURL: user && user.profile_picture2 ? user.profile_picture2 : user && user.profile_picture ? user.profile_picture : null,
            displayName: user && user.username ? user.username : null
        }

        if (!isStream) {
            event = {
                postId: postId,
                type: 'event',
                visibility: visibility,
                website: website,
                version: version,
                title: title,
                description: description,
                descriptionText: editorState.getCurrentContent().getPlainText(),
                tvRating: tvRating ? '18+' : '',
                poster: user && (user.profile_picture1 ? user.profile_picture1 : (user.profile_picture ? user.profile_picture : '')),
                startDate: startDT ? startDT.toDate().getTime() : moment().toDate().getTime(),
                estDuration: estDuration,
                cat: category,
                author: user && user.username ? user.username : '',
                authorPic: user && (user.profile_picture2 ? user.profile_picture2 : (user.profile_picture ? user.profile_picture : '')),
                uid: user && user.uid ? user.uid : '',
                contentLabels: contentLabels,
                noOfTickets: numTicket,
                price: ((typeof price === 'string' || price instanceof String) ? parseFloat(price) : price),
                expireTime: !expireTime ? '6 M' : expireTime,
                muteAudio: muteAudio,
                autoVideo: autoVideo,
                acceptTips: tipsShow,
                tipsValue: ((typeof tipsValue === 'string' || tipsValue instanceof String) ? parseFloat(tipsValue) : tipsValue),
                timeZone: timeZone,
                archiveEnabled: visibility && visibility === 'private' ? false : watchLater,
                archivePrice: ((typeof archivePrice === 'string' || archivePrice instanceof String) ? parseFloat(archivePrice) : archivePrice),
                archiveCustomCover: customCoverShow ? customCover : '',
                archiveStartDate: startDT ? startDT.toDate().getTime() : moment().toDate().getTime(),
                archiveStopDate: stopDT ? stopDT.toDate().getTime() : startDT ? startDT.add(6, 'months').toDate().getTime() : moment().add(6, 'months').toDate().getTime(),
                hosts: [hostData],
                cohosts: coHostShow ? cohosts : [],
                guestSpeakers: guestShow ? guestSpeakers : [],
                moderators: moderatorsShow ? moderators : [],
                featured: featured,
                sharedLink: sharedLink,
                branding: customCoverShow ? this.getBrandingData() : null,
                callParams: this.getCallParams()
            }

        }
        else {
            event = {
                postId: postId,
                type: 'stream',
                visibility: visibility,
                website: website,
                version: version,
                title: title,
                description: description,
                descriptionText: editorState.getCurrentContent().getPlainText(),
                tvRating: tvRating ? '18+' : '',
                poster: user && (user.profile_picture1 ? user.profile_picture1 : (user.profile_picture ? user.profile_picture : '')),
                estDuration: estDuration,
                cat: category,
                author: user && user.username ? user.username : '',
                authorPic: user && (user.profile_picture2 ? user.profile_picture2 : (user.profile_picture ? user.profile_picture : '')),
                uid: user && user.uid ? user.uid : '',
                contentLabels: contentLabels,
                noOfTickets: numTicket,
                price: ((typeof price === 'string' || price instanceof String) ? parseFloat(price) : price),
                expireTime: !(expireTime) ? '6 M' : expireTime,
                acceptTips: tipsShow,
                tipsValue: ((typeof tipsValue === 'string' || tipsValue instanceof String) ? parseFloat(tipsValue) : tipsValue),
                timeZone: timeZone,
                archiveEnabled: visibility && visibility === 'private' ? false : watchLater,
                archivePrice: ((typeof archivePrice === 'string' || archivePrice instanceof String) ? parseFloat(archivePrice) : archivePrice),
                archiveCustomCover: customCover,
                archiveStopDate: stopDate,
                featured: featured,
                sharedLink: sharedLink,
                branding: customCoverShow ? this.getBrandingData() : null,
                callParams: this.getCallParams()
            }
        }

        if (event) {
            this.setState({
                scheduling: true,
                error: null
            });

            if (!isStream) {
                firebaseClient.saveCalendarEvent(event).then((res) => {
                    if (!update) {
                        this.setState({
                            vanityId: res,
                            showVanityForm: true,
                            scheduling: false
                        });
                    }
                    else {
                        let id = res;
                        this.setState({ scheduling: false });
                        this.props.pushToEvent(id);
                    }
                }).catch((e) => {
                    this.setState({
                        scheduling: false,
                        error: 'Error creating event'
                    }, () => {
                        if (e && e.code && e.code === 'functions/unauthenticated') {
                            firebaseClient.logOut();
                        }
                    });
                });
            }
            else {
                firebaseClient.saveStream(event).then((res) => {
                    let id = res;
                    this.setState({ scheduling: false });
                    this.props.pushToStream(id);
                });
            }
        }
    }

    validDate(current) {
        var yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleCohostsDelete(i) {
        const { cohosts } = this.state;

        this.setState({
            cohosts: cohosts.filter((cohost, index) => index !== i)
        });
    }

    handleCohostsAddition(cohost) {
        if (cohost && cohost.email) {
            this.setState(state => ({ cohosts: [...state.cohosts, cohost], cohostsSuggestions: [] }));
        }
    }

    handleCohostsDrag(cohost, currPos, newPos) {
        const cohosts = [...this.state.cohosts];
        const newCohosts = cohosts.slice();

        newCohosts.splice(currPos, 1);
        newCohosts.splice(newPos, 0, cohost);

        this.setState({ cohosts: newCohosts });
    }

    handleCohostsInputChange(e) {

        let cohost = e;

        if (cohost)
            cohost = cohost.toLowerCase();

        if (this.validateEmail(e)) {
            this.setState({
                noCoHostMsg: 'Loading...'
            });
            firebaseClient.getFilteredUsersFS(cohost).then((res) => {
                if (res.data.length > 0) {

                    let suggestions = res.data.map(data => {
                        return {
                            id: data.id,
                            text: data.email,
                            email: data.email,
                            photoURL: data && data.profile_picture2 ? data.profile_picture2 : data && data.profile_picture ? data.profile_picture : null,
                            displayName: data.username
                        }
                    })

                    this.setState(
                        {
                            cohostsSuggestions: suggestions,
                            noCoHostMsg: ''
                        }
                    )
                } else {
                    this.setState({
                        noCoHostMsg: 'There are no users with this email'
                    });
                }
            });
        } else {
            this.setState(
                {
                    cohostsSuggestions: [],
                    noCoHostMsg: ''
                }
            )
        }
    }

    handleGuestsDelete(i) {
        const { guestSpeakers } = this.state;

        this.setState({
            guestSpeakers: guestSpeakers.filter((guest, index) => index !== i)
        });
    }

    handleGuestsAddition(guest) {
        if (guest && guest.email) {
            this.setState(state => ({ guestSpeakers: [...state.guestSpeakers, guest], guestSpeakersSuggestions: [] }));
        }
    }

    handleGuestsDrag(guest, currPos, newPos) {
        const guestSpeakers = [...this.state.guestSpeakers];
        const newGuests = guestSpeakers.slice();

        newGuests.splice(currPos, 1);
        newGuests.splice(newPos, 0, guest);

        this.setState({ guestSpeakers: newGuests });
    }

    handleGuestsInputChange(e) {

        let guest = e;

        if (guest)
            guest = guest.toLowerCase();

        if (this.validateEmail(e)) {
            this.setState({ noGuestMsg: 'Loading...' });
            firebaseClient.getFilteredUsersFS(guest).then((res) => {
                if (res.data.length > 0) {

                    let suggestions = res.data.map(data => {
                        return {
                            id: data.id,
                            text: data.email,
                            email: data.email,
                            photoURL: data && data.profile_picture2 ? data.profile_picture2 : data && data.profile_picture ? data.profile_picture : null,
                            displayName: data.username
                        }
                    })

                    this.setState(
                        {
                            guestSpeakersSuggestions: suggestions,
                            noGuestMsg: ''
                        }
                    )
                } else {
                    this.setState({
                        noGuestMsg: 'There are no users with this email'
                    });
                }
            });
        } else {
            this.setState(
                {
                    guestSpeakersSuggestions: [],
                    noGuestMsg: ''
                }
            )
        }
    }

    handleModeratorsDelete(i) {
        const { moderators } = this.state;

        this.setState({
            moderators: moderators.filter((moderator, index) => index !== i)
        });
    }

    handleModeratorsAddition(moderator) {
        if (moderator && moderator.email) {
            this.setState(state => ({ moderators: [...state.moderators, moderator], moderatorsSuggestions: [] }));
        }
    }

    handleModeratorsDrag(moderator, currPos, newPos) {
        const moderators = [...this.state.moderators];
        const newModerators = moderators.slice();

        newModerators.splice(currPos, 1);
        newModerators.splice(newPos, 0, moderator);

        this.setState({ moderators: newModerators });
    }

    handleModeratorsInputChange(e) {

        let moderator = e;

        if (moderator)
            moderator = moderator.toLowerCase();

        if (this.validateEmail(e)) {
            this.setState({ noModeratorMsg: 'Loading...' });
            firebaseClient.getFilteredUsersFS(moderator).then((res) => {
                if (res.data.length > 0) {

                    //
                    let suggestions = res.data.map(data => {
                        return {
                            id: data.id,
                            text: data.email,
                            email: data.email,
                            photoURL: data && data.profile_picture2 ? data.profile_picture2 : data && data.profile_picture ? data.profile_picture : null,
                            displayName: data.username
                        }
                    })

                    this.setState(
                        {
                            moderatorsSuggestions: suggestions,
                            noModeratorMsg: ''
                        }
                    )
                } else {
                    this.setState({
                        noModeratorMsg: 'There are no users with this email'
                    });
                }
            })
        } else {
            this.setState(
                {
                    moderatorsSuggestions: [],
                    noModeratorMsg: ''
                }
            )
        }
    }

    validStartDate(current) {
        const { timeZone } = this.state;

        if (current.isAfter(moment())) {
            return current;
        } else return moment(this.parseTime(moment().toDate().getTime(), timeZone));
    }

    handleChangeStartDate(e) {
        this.setState({
            startDT: this.validStartDate(e)
        }, () => {
            const { expireTime } = this.state;

            if (expireTime) {
                let splitValue = expireTime.split(' ')[0];
                let splitAdd = expireTime.split(' ')[1];
                this.setState({
                    stopDT: e.clone().add(parseInt(splitValue), splitAdd)
                });
            }
        })
    }

    handleChangeExpireTime(e) {
        const { startDT } = this.state;

        let value = e.target.value;
        let splitValue = value.split(' ')[0];
        let splitAdd = value.split(' ')[1];
        this.setState({
            expireTime: value,
            stopDT: startDT.clone().add(parseInt(splitValue), splitAdd)
        });
    }

    renderSubmitButton() {
        const { error, title, category, numTicket, scheduling, update, isStream } = this.state;

        return (
            <div className='submit-wrapper'>
                {error ?
                    <div className='switch-line'>
                        <p className='error-calendar'>{error}</p>
                    </div>
                    :
                    null
                }
                <div className='form-submit'>
                    <Button
                        type='default'
                        className='btn-submit-button'
                        onClick={() => this.handleAdd()}
                        disabled={!title || !category || !numTicket || scheduling}
                        loading={scheduling}
                    >
                        {update ? isStream ? 'Update Stream' : 'Update Event' : 'Schedule Event'}
                    </Button>
                </div>
                <div className='form-note'>Note: You can go back and edit later</div>
            </div>
        );
    }

    render() {
        const {
            loadingData,
            title,
            editorState,
            tvRating,
            visibility,
            contentLabels,
            coHostShow,
            guestShow,
            moderatorsShow,
            timeZone,
            customCoverShow,
            defaultCover,
            category,
            tipsShow,
            tipsValue,
            numTicket,
            price,
            watchLater,
            expireTime,
            estDuration,
            cohosts,
            cohostsSuggestions,
            guestSpeakers,
            guestSpeakersSuggestions,
            moderators,
            moderatorsSuggestions,
            showVanityForm,
            vanityId,
            noModeratorMsg,
            noCoHostMsg,
            noGuestMsg,
            update,
            archivePrice,
            scheduling,
            isStream,
            copyEvent,
            privileges,
            estDurationList,
            eventCardImage,
            addEventCardImageDisabled,
            eventSquareImage,
            horizontalEventPageImage,
            addHorizontalEventPageImageDisabled,
            verticalEventPageImage,
            addVerticalEventPageImageDisabled,
            croppedForm,
            crop,
            zoom,
            aspect,
            imgUrl,
            cropView,
            defaultLayout,
            labels,
            bannerImg,
            addBannerImgDisabled,
            bannerLink,
            previewImage,
            previewTitle
        } = this.state;
        const {
            categories,
            user
        } = this.props;

        let default_poster = defaultCover ? defaultCover : user && (user.profile_picture1 ? user.profile_picture1 : (user.profile_picture ? user.profile_picture : ''));

        return (
            <div>
                {!showVanityForm ?
                    <div className='form-wrapper'>
                        {loadingData ?
                            <div className='copy-event-text'>Please wait while loading...</div>
                            : copyEvent ?
                                <div className='copy-event-text'>Please pick a new date/time</div>
                                : null
                        }
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className='select-line'>
                                    <div className='select-text'>Type of Stream</div>
                                    <select
                                        className={classNames('form-control', { 'private': visibility === 'private' })}
                                        value={visibility}
                                        onChange={this.handleChangeVisibility}
                                        disabled={scheduling}
                                    >
                                        <option value='public'>Public</option>
                                        <option value='private'>Private</option>
                                    </select>
                                </div>
                                <div className='input-line padding-none'>
                                    <input
                                        type="text"
                                        placeholder="Enter stream title (max 100 chars)"
                                        value={title}
                                        maxLength="100"
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                        disabled={scheduling}
                                    />
                                    {/* <div className='text-small'>
                                        <small className="form-text text-muted">Please keep to 100 characters long</small>
                                        <small className="form-text text-muted">{`${title && title.length ? title.length : '0'}/100`}</small>
                                    </div> */}
                                </div>
                                <div className='textarea-line'>
                                    <div className='textarea-text'>Event Description</div>
                                    <Editor
                                        toolbar={{
                                            options: ['inline', 'list', 'emoji', 'history'],
                                            inline: {
                                                inDropdown: false,
                                                className: undefined,
                                                component: undefined,
                                                dropdownClassName: undefined,
                                                options: ['bold', 'italic', 'underline']
                                            },
                                            list: {
                                                inDropdown: false,
                                                className: undefined,
                                                component: undefined,
                                                dropdownClassName: undefined,
                                                options: ['unordered', 'ordered']
                                            }
                                        }}
                                        editorState={editorState}
                                        onEditorStateChange={(e) => this.setState({ editorState: e })}
                                        onContentStateChange={(e) => this.setState({ description: e })}
                                        placeholder='Start typing description'
                                        stripPastedStyles
                                    />
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Co Host</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={coHostShow}
                                                onChange={(e) => this.setState({ coHostShow: e.target.checked })}
                                                checked={coHostShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span className="role-text">(can also administer stream)</span>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !coHostShow })}>
                                        <ReactTags
                                            inputFieldPosition="top"
                                            placeholder='Email'
                                            tags={cohosts}
                                            autofocus={false}
                                            suggestions={cohostsSuggestions}
                                            handleDelete={this.handleCohostsDelete.bind(this)}
                                            handleAddition={this.handleCohostsAddition.bind(this)}
                                            handleDrag={this.handleCohostsDrag.bind(this)}
                                            handleInputChange={this.handleCohostsInputChange.bind(this)}
                                            delimiters={delimiters}
                                            allowAdditionFromPaste={false}
                                            allowDeleteFromEmptyInput={false}
                                        />
                                        {noCoHostMsg && <div className='no-user-message'>{noCoHostMsg}</div>}
                                    </div>
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Guest</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={guestShow}
                                                onChange={(e) => this.setState({ guestShow: e.target.checked })}
                                                checked={guestShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span className="role-text">(cannot administer stream)</span>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !guestShow })}>
                                        <ReactTags
                                            inputFieldPosition="top"
                                            placeholder='Email'
                                            tags={guestSpeakers}
                                            autofocus={false}
                                            suggestions={guestSpeakersSuggestions}
                                            handleDelete={this.handleGuestsDelete.bind(this)}
                                            handleAddition={this.handleGuestsAddition.bind(this)}
                                            handleDrag={this.handleGuestsDrag.bind(this)}
                                            handleInputChange={this.handleGuestsInputChange.bind(this)}
                                            delimiters={delimiters}
                                            allowAdditionFromPaste={false}
                                            allowDeleteFromEmptyInput={false}
                                        />
                                        {noGuestMsg && <div className='no-user-message'>{noGuestMsg}</div>}
                                    </div>
                                </div>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Moderator</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={moderatorsShow}
                                                onChange={(e) => this.setState({ moderatorsShow: e.target.checked })}
                                                checked={moderatorsShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span className="role-text">(can administer stream,<br />is invisible to audience)</span>
                                    </div>
                                    <div className={classNames('form-window moderator', { 'form-disabled': !moderatorsShow })}>
                                        <ReactTags
                                            inputFieldPosition="top"
                                            placeholder='Email'
                                            tags={moderators}
                                            autofocus={false}
                                            suggestions={moderatorsSuggestions}
                                            handleDelete={this.handleModeratorsDelete.bind(this)}
                                            handleAddition={this.handleModeratorsAddition.bind(this)}
                                            handleDrag={this.handleModeratorsDrag.bind(this)}
                                            handleInputChange={this.handleModeratorsInputChange.bind(this)}
                                            delimiters={delimiters}
                                            allowAdditionFromPaste={false}
                                            allowDeleteFromEmptyInput={false}
                                        />
                                        {noModeratorMsg && <div className='no-user-message'>{noModeratorMsg}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Tickets</div>
                                    </div>
                                    <div className='form-window'>
                                        <div className='inputs-div'>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={price}
                                                    disabled={scheduling || (process.env.build && process.env.build === 'nfl-aa')}
                                                    onChange={(e) => this.setState({ price: e.target.value })}
                                                >
                                                    {prices.map((price) => {
                                                        return (
                                                            <option key={price} value={price}>{`$${price}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={numTicket}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ numTicket: parseInt(e.target.value) })}
                                                >
                                                    {numTickets.map((numTicket) => {
                                                        return (
                                                            <option key={numTicket} value={numTicket}>{numTicket}</option>
                                                        );
                                                    })}
                                                </select>
                                                <small className="form-text text-muted" style={{ lineHeight: '22px' }}>Max 50,000 tickets</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNames('switch-line', { 'update-form': isStream }, { 'disabled': process.env.build && process.env.build === 'nfl-aa' })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Accept Tips</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={tipsShow}
                                                onChange={(e) => this.setState({ tipsShow: e.target.checked })}
                                                checked={tipsShow}
                                                disabled={scheduling || (process.env.build && process.env.build === 'nfl-aa')}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !tipsShow })}>
                                        <div className='inputs-div'>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={tipsValue}
                                                    disabled={scheduling || (process.env.build && process.env.build === 'nfl-aa')}
                                                    onChange={(e) => this.setState({ tipsValue: e.target.value })}
                                                >
                                                    {prices.map((price) => {
                                                        return (
                                                            <option key={price} value={price}>{`$${price}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classNames('date-time-line', { 'update-form': update || isStream })}>
                                    <div className='date-time-text'>
                                        Date / Time
                                        {user && user.administrator ?
                                            <Button
                                                type='default'
                                                className='btn-current-time'
                                                onClick={() => this.setState({ startDT: this.parseTime(moment().toDate().getTime(), timeZone) })}
                                            >
                                                Current time
                                            </Button>
                                            : null
                                        }
                                    </div>
                                    <DateTime
                                        inputProps={{
                                            placeholder: 'Start Date',
                                            className: 'date-input',
                                            disabled: scheduling,
                                            readOnly: true
                                        }}
                                        initialViewMode='days'
                                        value={this.state.startDT}
                                        isValidDate={this.validDate}
                                        onChange={this.handleChangeStartDate}
                                        displayTimeZone={timeZone || ''}
                                        timeConstraints={{ minutes: { step: 5 } }}
                                        utc={false}
                                    />
                                    <div className='date-time-flex'>
                                        <div className='date-time-text'>Est. duration</div>
                                        {estDurationList && estDurationList.length ?
                                            <div className='date-input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={estDuration}
                                                    disabled={scheduling}
                                                    onChange={(e) => this.setState({ estDuration: parseInt(e.target.value) })}
                                                >
                                                    {estDurationList.map((item) => {
                                                        return (
                                                            <option key={item.value} value={item.value}>{item.text}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            : null}
                                    </div>
                                    <select
                                        className="form-control"
                                        value={timeZone}
                                        onChange={(e) => this.setState({ timeZone: e.target.value })}
                                        disabled={scheduling}
                                    >
                                        {moment.tz.names().map((item) => {
                                            return (
                                                <option key={item} value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className={classNames('switch-line', { 'update-form': privileges ? !privileges.record_show : false }, { 'disabled': visibility && visibility === 'private' })}>
                                    <div className='switch-div'>
                                        <div className='switch-text--multi-row'>Make this event's recording available for people to watch later</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={watchLater}
                                                onChange={(e) => this.setState({ watchLater: e.target.checked })}
                                                checked={watchLater}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !watchLater })}>
                                        <div className='inputs-div'>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={archivePrice}
                                                    disabled={scheduling || (process.env.build && process.env.build === 'nfl-aa')}
                                                    onChange={(e) => this.setState({ archivePrice: e.target.value })}
                                                >
                                                    {prices.map((price) => {
                                                        return (
                                                            <option key={price} value={price}>{`$${price}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className='input-wrapper'>
                                                <select
                                                    className="form-control"
                                                    value={expireTime}
                                                    disabled={scheduling}
                                                    onChange={this.handleChangeExpireTime.bind(this)}
                                                >
                                                    <option disabled value=''>Expire Time</option>
                                                    <option value='1 d'>1 day</option>
                                                    <option value='2 d'>2 days</option>
                                                    <option value='1 w'>1 week</option>
                                                    <option value='2 w'>2 weeks</option>
                                                    <option value='1 M'>1 month</option>
                                                    <option value='6 M'>6 months</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                {categories && categories.length &&
                                    <div className='choose-line'>
                                        <div className='choose-text'>Categories</div>
                                        <div className="btn-group btn-group-toggle choose-button" data-toggle="buttons">
                                            {categories.map((item) => {
                                                return (
                                                    <label key={item.id} className={`btn btn-outline-secondary ${item.id === category ? 'active' : ''}`}>
                                                        <input
                                                            type="radio"
                                                            name={item.title}
                                                            id={item.id}
                                                            value={item.id}
                                                            onChange={(e) => this.setState({ category: e.target.value })}
                                                            autoComplete="off"
                                                            checked={item.id === category}
                                                            disabled={scheduling}
                                                        />
                                                        {item.title}
                                                    </label>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                <div className='checkbox-line'>
                                    <div className='checkbox-text'>Maturity Rating*</div>
                                    <label className="myCheckbox">
                                        <input
                                            type="checkbox"
                                            value={tvRating ? true : false}
                                            onChange={(e) => this.setState({ tvRating: e.target.checked ? '18+' : '' })}
                                            checked={tvRating ? true : false}
                                            disabled={scheduling}
                                        />
                                        <span className='checkbox-img' />
                                        <span className='checkbox-mature'>Mature Content</span>
                                    </label>
                                    <small className="form-text text-muted">*Note: Selecting none will be marked for general audience</small>
                                </div>
                                {contents && contents.length ?
                                    <div className='switch-line'>
                                        <div className='switch-div'>
                                            <div className='switch-text'>Content Labels</div>
                                        </div>
                                        <div className='form-window'>
                                            <small className="form-text text-muted">The following content labels are added to the rating to identify the show's content.</small>
                                            <div className="btn-group btn-group-toggle content-labels-button" data-toggle="buttons">
                                                {contents.map((item) => {
                                                    return (
                                                        <div key={item.id} className='contentLabels-div'>
                                                            <label className={`btn btn-rating btn-outline-secondary ${contentLabels.includes(item.title) ? 'active' : ''}`}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={item.title}
                                                                    id={item.id}
                                                                    value={item.title}
                                                                    onChange={this.handleChangeContentLabels.bind(this)}
                                                                    autoComplete="off"
                                                                    checked={contentLabels.includes(item.title)}
                                                                    disabled={scheduling}
                                                                />
                                                                {item.id}
                                                            </label>
                                                            <small className="form-text text-muted">{item.title}</small>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className={classNames('switch-line padding-none', { 'margin-submit': !firebaseClient.ifAdminOrAmbassador() })}>
                                    <div className='switch-div'>
                                        <div className='switch-text'>Use Custom Stream Cover</div>
                                        <label className="switch-control">
                                            <input
                                                type="checkbox"
                                                value={customCoverShow}
                                                onChange={(e) => this.setState({ customCoverShow: e.target.checked })}
                                                checked={customCoverShow}
                                                disabled={scheduling}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </div>
                                    <div className={classNames('form-window', { 'form-disabled': !customCoverShow })}>
                                        <small className="form-text text-muted text-default">Note: Default is the banner image from your profile</small>
                                        <div className='select-dual-wrapper bg-upload'>
                                            <p className='wrapp-p'>Event card</p>
                                            <div
                                                onClick={() => this.handleClickAddImage('event-card')}
                                                className={classNames('select-add-text', { 'disabled': addEventCardImageDisabled || scheduling })}
                                            >
                                                {eventSquareImage || eventCardImage ?
                                                    <span className='branding-line'>
                                                        <span
                                                            className='branding-img'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    previewImage: eventSquareImage ? eventSquareImage : eventCardImage,
                                                                    previewTitle: "Event card"
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                src={eventSquareImage ? eventSquareImage : eventCardImage}
                                                                className={classNames('event-bg', { 'card-image': eventCardImage }, { 'square-image': eventSquareImage })}
                                                                alt='img'
                                                                loading='lazy'
                                                            />
                                                            {/* <span className='rm-image'>
                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                            </span> */}
                                                        </span>
                                                        <p>Replace image <br /><span className='size'>(3000px x 3000px) or <br />(400px x 300px)</span></p>
                                                        <Button
                                                            type='default'
                                                            className='btn-delete-image'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    eventSquareImage: eventSquareImage ? null : null,
                                                                    originalSquareImage: eventSquareImage ? null : null,
                                                                    eventCardImage: eventSquareImage ? eventCardImage : null
                                                                });
                                                            }}
                                                            disabled={scheduling}
                                                        >
                                                            <img src={deleteIcon} loading='lazy' alt='X' className='button-delete-img' />
                                                        </Button>
                                                    </span>
                                                    :
                                                    <span className='branding-line'>
                                                        <span className='upload-img'>
                                                            <span className='branding-img'>
                                                                <img
                                                                    src={default_poster}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.setState({
                                                                            previewImage: default_poster,
                                                                            previewTitle: "Event card"
                                                                        })
                                                                    }}
                                                                    className='event-bg card-image'
                                                                    alt='img'
                                                                    loading='lazy'
                                                                />
                                                            </span>
                                                            <p>Replace image <br /><span className='size'>(3000px x 3000px) or <br />(400px x 300px)</span></p>
                                                        </span>
                                                    </span>
                                                }
                                                <input
                                                    type="file"
                                                    className='add-file'
                                                    ref={this.addCardRef}
                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                    onChange={(event) => this.handleSetCropImage(event, 'event-card')}
                                                />
                                            </div>
                                        </div>
                                        <div className='select-dual-wrapper bg-upload'>
                                            <p className='wrapp-p'>{`Horizontal event page & audience lobby`}</p>
                                            <div
                                                onClick={() => this.handleClickAddImage('horizontal-image')}
                                                className={classNames('select-add-text', { 'disabled': addHorizontalEventPageImageDisabled || scheduling })}
                                            >
                                                {horizontalEventPageImage ?
                                                    <span className='branding-line'>
                                                        <span
                                                            className='branding-img'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    previewImage: horizontalEventPageImage,
                                                                    previewTitle: `${`Horizontal event page & audience lobby`}`
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                src={horizontalEventPageImage}
                                                                className='event-bg horizontal'
                                                                alt='img'
                                                                loading='lazy'
                                                            />
                                                            {/* <span className='rm-image'>
                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                            </span> */}
                                                        </span>
                                                        <p>Replace background image <span className='size'>(1280px x 720px)</span></p>
                                                        <Button
                                                            type='default'
                                                            className='btn-delete-image'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({ horizontalEventPageImage: null })
                                                            }}
                                                            disabled={scheduling}
                                                        >
                                                            <img src={deleteIcon} loading='lazy' alt='X' className='button-delete-img' />
                                                        </Button>
                                                    </span>
                                                    :
                                                    <span className='branding-line'>
                                                        <span className='upload-img'>
                                                            <span className='branding-img'>
                                                                <img
                                                                    src={default_poster}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.setState({
                                                                            previewImage: default_poster,
                                                                            previewTitle: `${`Horizontal event page & audience lobby`}`
                                                                        })
                                                                    }}
                                                                    className='event-bg horizontal'
                                                                    alt='img'
                                                                    loading='lazy'
                                                                />
                                                            </span>
                                                            <p>Replace background image <span className='size'>(1280px x 720px)</span></p>
                                                        </span>
                                                    </span>
                                                }
                                                <input
                                                    type="file"
                                                    className='add-file'
                                                    ref={this.addHorizontalRef}
                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                    onChange={(event) => this.handleSetCropImage(event, 'horizontal-image')}
                                                />
                                            </div>
                                        </div>
                                        <div className='select-dual-wrapper bg-upload'>
                                            <p className='wrapp-p'>Vertical event page</p>
                                            <div
                                                onClick={() => this.handleClickAddImage('vertical-image')}
                                                className={classNames('select-add-text', { 'disabled': addVerticalEventPageImageDisabled || scheduling })}
                                            >
                                                {verticalEventPageImage ?
                                                    <span className='branding-line'>
                                                        <span
                                                            className='branding-img'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    previewImage: verticalEventPageImage,
                                                                    previewTitle: "Vertical event page"
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                src={verticalEventPageImage}
                                                                className='event-bg vertical'
                                                                alt='img'
                                                                loading='lazy'
                                                            />
                                                            {/* <span className='rm-image'>
                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                            </span> */}
                                                        </span>
                                                        <p>Replace background image <span className='size'>(400px x 800px)</span></p>
                                                        <Button
                                                            type='default'
                                                            className='btn-delete-image'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({ verticalEventPageImage: null })
                                                            }}
                                                            disabled={scheduling}
                                                        >
                                                            <img src={deleteIcon} loading='lazy' alt='X' className='button-delete-img' />
                                                        </Button>
                                                    </span>
                                                    :
                                                    <span className='branding-line'>
                                                        <span className='upload-img'>
                                                            <span className='branding-img'>
                                                                <img
                                                                    src={default_poster}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.setState({
                                                                            previewImage: default_poster,
                                                                            previewTitle: "Vertical event page"
                                                                        })
                                                                    }}
                                                                    className='event-bg vertical'
                                                                    alt='img'
                                                                    loading='lazy'
                                                                />
                                                            </span>
                                                            <p>Replace background image <span className='size'>(400px x 800px)</span></p>
                                                        </span>
                                                    </span>
                                                }
                                                <input
                                                    type="file"
                                                    className='add-file'
                                                    ref={this.addVerticalRef}
                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                    onChange={(event) => this.handleSetCropImage(event, 'vertical-image')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!firebaseClient.ifAdminOrAmbassador() ? this.renderSubmitButton() : null}
                            </div>
                        </div>
                        {firebaseClient.ifAdminOrAmbassador() ?
                            <div className='form-div-window'>
                                <div className='form-div'>
                                    <div className={classNames('switch-line padding-none', { 'update-form': isStream })}>
                                        <div className='switch-div'>
                                            <div className='switch-text'>Stream Layout</div>
                                        </div>
                                        <div className='form-window'>
                                            <div className='radio-groups radio-wrap'>
                                                <div className='radio-item'>
                                                    <img src={adParticipants} onClick={() => this.setState({ previewImage: adParticipants, previewTitle: 'Stream Layout - Ad+Participants' })} alt='' className='radio-image' loading='lazy' />
                                                    <input
                                                        type="radio"
                                                        id="radio4"
                                                        value={Defines.RecordingLayout.AdParticipants}
                                                        onChange={this.handleChangeLayout}
                                                        checked={defaultLayout && defaultLayout === Defines.RecordingLayout.AdParticipants}
                                                        disabled={scheduling}
                                                    />
                                                    <label className='label-text' htmlFor="radio4" />
                                                    <span className='radio-text'>Ad+Participants</span>
                                                </div>
                                                <div className='radio-item'>
                                                    <img src={speaker} onClick={() => this.setState({ previewImage: speaker, previewTitle: 'Stream Layout - Ad+Active Speaker' })} alt='' className='radio-image' loading='lazy' />
                                                    <input
                                                        type="radio"
                                                        id="radio3"
                                                        value={Defines.RecordingLayout.Speaker}
                                                        onChange={this.handleChangeLayout}
                                                        checked={defaultLayout && defaultLayout === Defines.RecordingLayout.Speaker}
                                                        disabled={scheduling}
                                                    />
                                                    <label className='label-text' htmlFor="radio3" />
                                                    <span className='radio-text'>Ad+Active Speaker</span>
                                                </div>
                                                <div className='radio-item'>
                                                    <img src={zoomOut} onClick={() => this.setState({ previewImage: zoomOut, previewTitle: 'Stream Layout - Zoom out' })} alt='' className='radio-image' loading='lazy' />
                                                    <input
                                                        type="radio"
                                                        id="radio1"
                                                        value={Defines.RecordingLayout.NoCrop}
                                                        onChange={this.handleChangeLayout}
                                                        checked={defaultLayout && defaultLayout === Defines.RecordingLayout.NoCrop}
                                                        disabled={scheduling}
                                                    />
                                                    <label className='label-text' htmlFor="radio1" />
                                                    <span className='radio-text'>Zoom out</span>
                                                </div>
                                                <div className='radio-item'>
                                                    <img src={zoomIn} onClick={() => this.setState({ previewImage: zoomIn, previewTitle: 'Stream Layout - Zoom in' })} alt='' className='radio-image' loading='lazy' />
                                                    <input
                                                        type="radio"
                                                        id="radio2"
                                                        value={Defines.RecordingLayout.Crop}
                                                        onChange={this.handleChangeLayout}
                                                        checked={defaultLayout && defaultLayout === Defines.RecordingLayout.Crop}
                                                        disabled={scheduling}
                                                    />
                                                    <label className='label-text' htmlFor="radio2" />
                                                    <span className='radio-text'>Zoom in</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames('switch-line animation', { 'update-form': isStream })}>
                                        <div className='switch-div'>
                                            <div className='switch-text'>Ad Banner</div>
                                        </div>
                                        <div className='form-window no-margin'>
                                            <small className="form-text text-muted text-default mg-bottom-0">Note: Banner will be displayed only in "Ad+Active Speaker" and "Ad+Participants"</small>
                                            <div className='select-dual-wrapper bg-upload no-margin padding-05'>
                                                <div
                                                    onClick={() => this.handleClickAddImage('banner')}
                                                    className={classNames('select-add-text', { 'disabled': addBannerImgDisabled || scheduling })}
                                                >
                                                    {bannerImg ?
                                                        <span className='branding-line'>
                                                            <span
                                                                className='branding-img'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.setState({
                                                                        previewImage: bannerImg,
                                                                        previewTitle: "Ad Banner"
                                                                    })
                                                                }}
                                                            >
                                                                <img
                                                                    src={bannerImg}
                                                                    className='event-bg banner'
                                                                    alt='img'
                                                                    loading='lazy'
                                                                />
                                                                {/* <span className='rm-image'>
                                                                    <img src={deleteIcon} loading='lazy' alt='X' />
                                                                </span> */}
                                                            </span>
                                                            <p>Replace ad banner <span className='size'>(1920px x 240px)</span></p>
                                                            <Button
                                                                type='default'
                                                                className='btn-delete-image'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.setState({ bannerImg: null })
                                                                }}
                                                                disabled={scheduling}
                                                            >
                                                                <img src={deleteIcon} loading='lazy' alt='X' className='button-delete-img' />
                                                            </Button>
                                                        </span>
                                                        :
                                                        <span className='branding-line'>
                                                            <span className='upload-img'>
                                                                <span className='branding-img'>
                                                                    <img
                                                                        src={uploadIcon}
                                                                        className='upload-image'
                                                                        alt='img'
                                                                        loading='lazy'
                                                                    />
                                                                </span>
                                                                <p>Upload ad banner <span className='size'>(1920px x 240px)</span></p>
                                                            </span>
                                                        </span>
                                                    }
                                                    <input
                                                        type="file"
                                                        className='add-file'
                                                        ref={this.addBannerImgRef}
                                                        accept="image/png,image/jpeg,image/svg+xml"
                                                        onChange={(event) => this.handleSetCropImage(event, 'banner')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='input-line padding-none input-button'>
                                                <input
                                                    type="text"
                                                    placeholder="Ad banner click URL"
                                                    value={bannerLink}
                                                    onChange={(e) => this.setState({ bannerLink: e.target.value })}
                                                    disabled={scheduling}
                                                />
                                                {/* <Button
                                                    type='default'
                                                    className='btn-save-banner'
                                                    onClick={this.handleAddBanner}
                                                    disabled={!bannerImg || scheduling}
                                                >
                                                    {bannerId ? 'update' : 'save'}
                                                </Button> */}
                                            </div>
                                            {/* {banners && banners.length ?
                                                banners.map((item) => {
                                                    return (
                                                        <div key={item.id} className='banner-item'>
                                                            <input
                                                                type="radio"
                                                                id={item.id}
                                                                value={item.id}
                                                                onChange={this.handleChangeDefaultBanner}
                                                                checked={item.default}
                                                                disabled={scheduling}
                                                            />
                                                            <label className='banner-text' htmlFor={item.id} />
                                                            <img
                                                                onClick={() => this.setState({ previewImage: item.img })}
                                                                src={item.img}
                                                                loading='lazy'
                                                                alt=''
                                                                className='banner-img'
                                                            />
                                                            {item.link ?
                                                                <a href={item.link} target="_blank">
                                                                    <img src={linkIcon} loading='lazy' alt='' className='banner-link-img' />
                                                                </a>
                                                                : null
                                                            }
                                                            <Button
                                                                type='default'
                                                                className='btn-delete-banner'
                                                                onClick={() => this.handleDeleteBanner(item.id)}
                                                                disabled={scheduling}
                                                            >
                                                                <img src={deleteIcon} loading='lazy' alt='X' className='banner-delete-img' />
                                                            </Button>
                                                        </div>
                                                    )
                                                })
                                                : null} */}
                                        </div>
                                    </div>
                                    <div className={classNames('switch-line margin-submit', { 'update-form': isStream })}>
                                        <div className='switch-div'>
                                            <div className='switch-text'>Participant Name Labels</div>
                                        </div>
                                        <div className='form-window margin-top'>
                                            <div className='radio-groups'>
                                                <div className='radio-item'>
                                                    <input
                                                        type="radio"
                                                        id="label1"
                                                        value={Defines.Labels.Default}
                                                        onChange={this.handleChangeLabels}
                                                        checked={labels && labels === Defines.Labels.Default}
                                                        disabled={scheduling}
                                                    />
                                                    <label htmlFor="label1">
                                                        <span className='label-span default'>Default</span>
                                                    </label>
                                                </div>
                                                <div className='radio-item'>
                                                    <input
                                                        type="radio"
                                                        id="label2"
                                                        value={Defines.Labels.Outlined}
                                                        onChange={this.handleChangeLabels}
                                                        checked={labels && labels === Defines.Labels.Outlined}
                                                        disabled={scheduling}
                                                    />
                                                    <label htmlFor="label2">
                                                        <span className='label-span outlined'>Outlined</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderSubmitButton()}
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    :
                    <div className='form-wrapper'>
                        <div className='form-div-window'>
                            <div className='form-div'>
                                <div className='vanity-link-line'>
                                    <div className='copy-vanity'>{`You have scheduled an event on ${strings.fullName}!`}</div>
                                    <div className='copy-vanity'>You can view all your scheduled events on your MyStuff Page.</div>
                                </div>
                                <div className='form-submit'>
                                    <Button
                                        type='default'
                                        className='btn-submit-button'
                                        onClick={() => this.props.pushToEvent(vanityId)}
                                    >
                                        {`Confirm & Exit`}
                                    </Button>
                                </div>
                                <div className='form-note'>Note: You can revisit this page for future</div>
                            </div>
                        </div>
                    </div>
                }
                {cropView ?
                    <div className={classNames('crop-container', { 'croppedForm': croppedForm })}>
                        <Cropper
                            image={imgUrl}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={(crop) => this.setState({ crop: crop })}
                            onCropComplete={(croppedArea, croppedAreaPixels) => this.setState({ croppedAreaPixels: croppedAreaPixels })}
                            onZoomChange={(zoom) => this.setState({ zoom: zoom })}
                        />
                        <div className='crop-buttons'>
                            {croppedForm ?
                                <div className='form-buttons'>
                                    <div className='form-radio'>
                                        <input
                                            type="radio"
                                            value="1"
                                            onChange={(e) => this.setState({ aspect: 1 })}
                                            autoComplete="off"
                                            checked={aspect && aspect === 1 ? true : false}
                                            disabled={scheduling}
                                        />
                                        <span>3000px x 3000px</span>
                                    </div>
                                    <div className='form-radio'>
                                        <input
                                            type="radio"
                                            value="4/3"
                                            onChange={(e) => this.setState({ aspect: 4 / 3 })}
                                            autoComplete="off"
                                            checked={aspect && aspect === 4 / 3 ? true : false}
                                            disabled={scheduling}
                                        />
                                        <span>400px x 300px</span>
                                    </div>
                                </div>
                                : null}
                            <Button
                                type="border"
                                onClick={this.cancelCrop}
                                className='btn btn-crop-close'
                            >
                                Cancel
                            </Button>
                            <Button
                                type="border"
                                onClick={this.saveCropImage}
                                className='btn btn-crop-done'
                            >
                                Crop
                            </Button>
                        </div>
                    </div>
                    : null}
                {previewImage ?
                    <Preview
                        previewImage={previewImage}
                        previewTitle={previewTitle}
                        closePreview={() => this.setState({ previewImage: null, previewTitle: '' })}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        categories: state.firebase.categories,
        defaults: state.firebase.defaults,
        ambassadors: state.firebase.ambassadors
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToEvent: (id) => {
            dispatch(nativePush(`/event/${id}`));
        },
        pushToStream: (id) => {
            dispatch(nativePush(`/stream/${id}`));
        }
    };
};

const CalendarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Calendar);

export default CalendarContainer;
