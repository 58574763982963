import * as devicesActions from "../actions/devices_actions";
import Cookies from "../libs/Storage";

var expireTime = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const default_cookies_param = {
    path: "/",
    expires: expireTime,
    secure: true,
    sameSite: 'none',
};

class DeviceManager {

    #streams = [];

    constructor(store) {
        this._store = store;

        this.onDeviceChange = this.onDeviceChange.bind(this);
    }

    setStore(store) {
        this._store = store;
    }

    reportError(error) {
        console.error(error);
    }

    onDeviceChange() {
        this.releaseAudioStream()
            .then(() => this.releaseVideoStream())
            .then(() => {
                this._store.dispatch(devicesActions.setDevicesData({
                    error: null,
                    loading: true
                }));

                this.init();
            });
    }

    stopAllStreams() {
        if (this.#streams && this.#streams.length) {
            return this.#streams.filter((stream) => stream.active).map(async (stream, i) => {
                if (stream && stream.active && stream.getTracks) {
                    await stream.getTracks().forEach(async (track) => {
                        await track.stop();
                    });
                }
                delete this.#streams[i];
                return i;
            });
        }
    }

    handleJoin() {
        const { audioInputDevice, videoInputDevice, audioOutputDevice, videoEnabled, audioEnabled } = this._store.getState().devices;

        const payload = {
            // audioInputDevice: audioInputDevice,
            // videoInputDevice: videoInputDevice,
            // audioOutputDevice: audioOutputDevice,
            // videoEnabled: videoEnabled,
            // audioEnabled: audioEnabled,
            preConfiguration: false
        };
        console.log('joinRoom', payload);
        this._store.dispatch(devicesActions.joinRoom(payload));
    }

    attachMediaStream(videoRef, stream) {
        // navigator.attachMediaStream(videoRef, stream);
        videoRef.srcObject = stream;
    }

    attachDeviceMediaStream(videoRef, videoDevice, audioDevice = null) {
        if (videoRef && videoDevice && videoDevice.deviceId) {
            let audioConstraints = false;

            if (audioDevice && audioDevice.deviceId) {
                audioConstraints = {
                    deviceId: { exact: audioDevice.deviceId },
                };
            }

            let videoConstraints = {
                deviceId: { exact: videoDevice.deviceId },
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 30 }
            };
            return navigator.mediaDevices.getUserMedia({ audio: audioConstraints, video: videoConstraints })
                .then((stream) => {
                    this.#streams.push(stream);
                    this._store.dispatch(devicesActions.setDevicesData({
                        userVideoStream: stream
                    }));
                    videoRef.srcObject = stream;
                    return stream;
                });
        }
    }

    async releaseAllStreams() {
        const { userVideoStream, userAudioStream } = this._store.getState().devices;

        console.log('releaseAllStreams', userVideoStream, userAudioStream);

        if (userVideoStream) await this.releaseVideoStream();
        if (userAudioStream) await this.releaseAudioStream();

        return;
    }


    async leaveConference() {
        console.log('leaveConference');

        await navigator.mediaDevices.removeEventListener("devicechange", this.onDeviceChange);

        await this.releaseAllStreams();
    }

    async stopStreamTracks(stream) {
        if (stream && stream.getTracks) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }

    async releaseVideoStream() {
        const { userVideoStream } = this._store.getState().devices;

        if (userVideoStream) {
            userVideoStream.getTracks().forEach((track) => {
                track.stop();
            });

            this._store.dispatch(devicesActions.setDevicesData({
                userVideoStream: null
            }));
        }
    }

    async releaseAudioStream() {
        const { userAudioStream } = this._store.getState().devices;

        if (userAudioStream) {
            userAudioStream.getTracks().forEach((track) => {
                track.stop();
            });

            this._store.dispatch(devicesActions.setDevicesData({
                userAudioStream: null
            }));
        }
    }

    restartCamera() {
        const { audioInputDevice, videoInputDevice } = this._store.getState().devices;

        let audioConstraints = false;
        if (audioInputDevice !== null) {
            audioConstraints = {
                deviceId: { exact: audioInputDevice.deviceId },
            };
        }

        let videoConstraints = false;
        if (videoInputDevice !== null) {
            videoConstraints = {
                deviceId: { exact: videoInputDevice.deviceId },
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 30 }
            };
        }

        return navigator.mediaDevices.getUserMedia({ audio: audioConstraints, video: false })
            .then((audioStream) => {
                this.#streams.push(audioStream);
                return navigator.mediaDevices.getUserMedia({ audio: false, video: videoConstraints })
                    .then((videoStream) => {
                        this.#streams.push(videoStream);
                        // this.attachMediaStream(this.video, videoStream);
                        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
                            return navigator.mediaDevices.enumerateDevices().then((sources) => {
                                let videoInputDevices = [];
                                /* GET SOURCES */
                                sources.forEach((source) => {
                                    if (source.kind === "videoinput" && source.deviceId !== "") {
                                        videoInputDevices.push(source);
                                    }
                                });

                                this._store.dispatch(devicesActions.setDevicesData({
                                    userVideoStream: videoStream,
                                    userAudioStream: audioStream,
                                    videoInputDevices: videoInputDevices
                                }));
                            });
                        }
                    });
            })
            .catch((error) => {
                this.reportError(error.message);
                this._store.dispatch(devicesActions.setVideoEnabled(false));
            });
    }

    async handleChangeAudioInputDevice(e) {
        const { audioInputDevices } = this._store.getState().devices;

        const deviceId = e.target.value;
        let device = null;

        await this.releaseAudioStream();

        this._store.dispatch(devicesActions.setDevicesData({
            lockJoin: true
        }));

        if (audioInputDevices && audioInputDevices.length && deviceId) {
            device = audioInputDevices.filter((item) => item && item.deviceId && item.deviceId === deviceId)[0];
        }

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: device.deviceId } }, video: false });

        this.#streams.push(audioStream);

        this._store.dispatch(devicesActions.setDevicesData({
            userAudioStream: audioStream,
            lockJoin: false
        }));

        this._store.dispatch(devicesActions.setAudioInputDevice(device));
    }

    handleChangeAudioOutputDevice(e) {
        const { audioOutputDevices } = this._store.getState().devices;

        const deviceId = e.target.value;
        let device = null;

        if (audioOutputDevices && audioOutputDevices.length && deviceId) {
            device = audioOutputDevices.filter((item) => item && item.deviceId && item.deviceId === deviceId)[0];
        }

        this._store.dispatch(devicesActions.setAudioOutputDevice(device));
    }

    async handleChangeVideoInputDevice(e) {
        const { videoInputDevices } = this._store.getState().devices;

        const deviceId = e.target.value;
        let device = null;

        await this.releaseVideoStream();

        this._store.dispatch(devicesActions.setDevicesData({
            lockJoin: true
        }));

        if (videoInputDevices && videoInputDevices.length && deviceId) {
            device = videoInputDevices.filter((item) => item && item.deviceId && item.deviceId === deviceId)[0];
        }

        const videoStream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: { exact: device.deviceId },
                aspectRatio: { ideal: 16 / 9 },
                frameRate: { ideal: 30 }
            },
            audio: false
        });

        this.#streams.push(videoStream);

        this._store.dispatch(devicesActions.setDevicesData({
            userVideoStream: videoStream,
            lockJoin: false
        }));

        this._store.dispatch(devicesActions.setVideoInputDevice(device));
        // this.attachMediaStream(videoStream);
    }

    async getVideoStream(ref) {
        const { videoInputDevice } = this._store.getState().devices;

        if (!videoInputDevice || (videoInputDevice && !videoInputDevice.deviceId)) return null;

        return this.attachDeviceMediaStream(ref, videoInputDevice);
    }

    async checkPermissions() {
        //console.log('About to check access to audio/video devices', { audio: constraints.audio, video: constraints.video})
        await navigator.mediaDevices.addEventListener("devicechange", this.onDeviceChange);

        return await navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then((stream) => {
                this.#streams.push(stream);
                //console.log('Got stream, about to close it');
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
                return false;
            })
            .catch((err) => {
                console.error("Could not get access to required media", err);
                return true;
            });
    }

    async init() {
        const { userVideoStream, userAudioStream, videoEnabled, error } = this._store.getState().devices;

        let audioInputDevices = [];
        let videoInputDevices = [];
        let audioOutputDevices = [];
        let videoCookieExist = false;
        let outputCookieExist = false;
        let inputCookieExist = false;
        if (userVideoStream) await this.releaseVideoStream();
        if (userAudioStream) await this.releaseAudioStream();
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices
                .enumerateDevices()
                .then((sources) => {
                    /* GET SOURCES */
                    sources.forEach((source) => {
                        if (source.kind === "videoinput" && source.deviceId !== "") {
                            const device = Cookies.getDevice("camera");
                            if (device && device.deviceId === source.deviceId) videoCookieExist = true;
                            videoInputDevices.push(source);
                        }
                        if (source.kind === `audioinput` && source.deviceId !== "") {
                            const device = Cookies.getDevice("input");
                            if (device && device.deviceId === source.deviceId) inputCookieExist = true;
                            audioInputDevices.push(source);
                        }
                        if (source.kind === "audiooutput" && source.deviceId !== "") {
                            const device = Cookies.getDevice("output");
                            if (device && device.deviceId === source.deviceId) outputCookieExist = true;
                            audioOutputDevices.push(source);
                        }
                    });
                })
                .then(() => {
                    if (audioInputDevices.length > 0) {
                        Promise.resolve()
                            .then(() => {
                                return navigator.mediaDevices
                                    .getUserMedia({
                                        audio: true,
                                        video: videoEnabled && videoInputDevices.length > 0,
                                    }).then((stream) => {
                                        this.#streams.push(stream);
                                    })
                                    .catch((error) => {
                                        console.warn(
                                            "Could not get audio or video",
                                            error && error.message
                                        );
                                        // Try audio only
                                        return navigator.mediaDevices
                                            .getUserMedia({
                                                audio: true,
                                                video: false,
                                            })
                                            .then((stream) => {
                                                this.#streams.push(stream);
                                                // Disable video
                                                this._store.dispatch(devicesActions.setDevicesData({
                                                    videoEnabled: false,
                                                    error: 'errorPermissionDeniedMicrophoneCamera',
                                                    loading: false
                                                }));
                                                return stream;
                                            });
                                    });
                            })
                            .then((stream) => {
                                audioInputDevices = [];
                                videoInputDevices = [];
                                audioOutputDevices = [];
                                navigator.mediaDevices.enumerateDevices()
                                    .then((sources) => {
                                        videoCookieExist = false;
                                        outputCookieExist = false;
                                        inputCookieExist = false;

                                        /* GET SOURCES */
                                        sources.forEach((source) => {
                                            if (
                                                source.kind === "videoinput" &&
                                                source.deviceId !== ""
                                            ) {
                                                const device = Cookies.getDevice("camera");
                                                if (device && device.deviceId === source.deviceId)
                                                    videoCookieExist = true;
                                                videoInputDevices.push(source);
                                            }
                                            if (
                                                source.kind === `audioinput` &&
                                                source.deviceId !== ""
                                            ) {
                                                const device = Cookies.getDevice("input");
                                                if (device && device.deviceId === source.deviceId)
                                                    inputCookieExist = true;
                                                audioInputDevices.push(source);
                                            }
                                            if (
                                                source.kind === "audiooutput" &&
                                                source.deviceId !== ""
                                            ) {
                                                const device = Cookies.getDevice("output");
                                                if (device && device.deviceId === source.deviceId)
                                                    outputCookieExist = true;
                                                audioOutputDevices.push(source);
                                            }
                                        });

                                        /* OUTPUT AUDIO MANAGEMENT */
                                        let audioOutputDevice;
                                        if (audioOutputDevices && audioOutputDevices.length > 0) {
                                            let audioOutputDevice = audioOutputDevices.find(
                                                (device) => device.deviceId === "default"
                                            );
                                            if (!audioOutputDevice)
                                                audioOutputDevice = audioOutputDevices[0];
                                            if (!outputCookieExist) {
                                                this._store.dispatch(devicesActions.setDevicesData({
                                                    audioOutputDevices: audioOutputDevices
                                                }));
                                                this._store.dispatch(devicesActions.setAudioOutputDevice(audioOutputDevice));
                                            } else {
                                                audioOutputDevice = Cookies.getDevice("output");
                                                this._store.dispatch(devicesActions.setDevicesData({
                                                    audioOutputDevices: audioOutputDevices
                                                }));
                                                this._store.dispatch(devicesActions.setAudioOutputDevice(audioOutputDevice));
                                            }
                                        }

                                        /* INPUT VIDEO MANAGEMENT */
                                        let videoInputDevice;
                                        if (videoInputDevices.length > 0) {
                                            if (!videoCookieExist) {
                                                videoInputDevice = videoInputDevices.find(
                                                    (device) => device.deviceId === "default"
                                                );
                                                if (!videoInputDevice) videoInputDevice = videoInputDevices[0];
                                            } else {
                                                videoInputDevice = Cookies.getDevice("camera");
                                            }

                                            this._store.dispatch(devicesActions.setDevicesData({
                                                videoInputDevices: videoInputDevices
                                            }));

                                            this._store.dispatch(devicesActions.setVideoInputDevice(videoInputDevice));
                                        } else {
                                            this._store.dispatch(devicesActions.setVideoEnabled(false));
                                        }

                                        /* INPUT AUDIO MANAGEMENT */
                                        let audioInputDevice;
                                        if (audioInputDevices.length > 0) {
                                            if (!inputCookieExist) {
                                                let audioInputDevice = audioInputDevices.find(
                                                    (device) => device.deviceId === "default"
                                                );
                                                if (!audioInputDevice) audioInputDevice = audioInputDevices[0];

                                                this._store.dispatch(devicesActions.setDevicesData({
                                                    audioInputDevices: audioInputDevices
                                                }));

                                                this._store.dispatch(devicesActions.setAudioInputDevice(audioInputDevice));
                                            } else {
                                                audioInputDevice = Cookies.getDevice("input");

                                                this._store.dispatch(devicesActions.setDevicesData({
                                                    audioInputDevices: audioInputDevices
                                                }));

                                                this._store.dispatch(devicesActions.setAudioInputDevice(audioInputDevice));
                                            }
                                        } else {
                                            this.reportError('noAudioDevice');

                                            this._store.dispatch(devicesActions.setDevicesData({
                                                error: 'noAudioDevice',
                                                loading: false
                                            }));

                                            Cookies.remove("input");
                                            Cookies.remove("output");
                                            setTimeout(() => {
                                                this.onDeviceChange();
                                            }, 3000);
                                        }

                                        /* GETUSERMEDIA FROM PREVIOUS MANAGEMENT */
                                        if (audioInputDevices.length > 0 && error == null) {
                                            this._store.dispatch(devicesActions.setDevicesData({
                                                loading: false
                                            }));
                                            return navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: audioInputDevice && audioInputDevice.deviceId ? audioInputDevice.deviceId : null } }, video: false })
                                                .then((audioStream) => {
                                                    this.#streams.push(audioStream);
                                                    if (videoEnabled) {
                                                        const videoConstraints = {
                                                            deviceId: { exact: videoInputDevice.deviceId },
                                                            aspectRatio: { ideal: 16 / 9 },
                                                            frameRate: { ideal: 30 }
                                                        };
                                                        return navigator.mediaDevices.getUserMedia({ audio: false, video: videoConstraints })
                                                            .then(async (videoStream) => {
                                                                this.#streams.push(videoStream);
                                                                this._store.dispatch(devicesActions.setDevicesData({
                                                                    userAudioStream: audioStream,
                                                                    userVideoStream: videoStream
                                                                }));
                                                            });
                                                    } else {
                                                        this._store.dispatch(devicesActions.setDevicesData({
                                                            userAudioStream: audioStream
                                                        }));
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.error('Could not get user device media', err.message, audioInputDevice, videoInputDevice)
                                                    this.reportError(err.message);
                                                    return false;
                                                });
                                        } else {
                                            this.reportError("No input device detected");
                                        }
                                    })
                                    .then(() => {
                                        if (stream && stream.getTracks) {
                                            stream.getTracks().forEach((track) => {
                                                track.stop();
                                            });
                                        }
                                    });
                            })
                            .catch((error) => {
                                if (videoEnabled) {
                                    this.reportError('errorPermissionDeniedMicrophoneCamera');
                                    this._store.dispatch(devicesActions.setDevicesData({
                                        error: 'errorPermissionDeniedMicrophoneCamera',
                                        loading: false
                                    }));
                                } else {
                                    this.reportError('errorPermissionDeniedMicrophone');
                                    this._store.dispatch(devicesActions.setDevicesData({
                                        error: 'errorPermissionDeniedMicrophone',
                                        loading: false
                                    }));
                                }
                            });
                    } else {
                        this.reportError('noAudioDevice');

                        this._store.dispatch(devicesActions.setDevicesData({
                            error: 'noAudioDevice',
                            loading: false
                        }));

                        Cookies.remove("input");
                        Cookies.remove("output");
                        setTimeout(() => {
                            this.onDeviceChange();
                        }, 3000);
                    }
                });
        }
    }

    async handleVideoEnabledChanged(event) {
        const target = event.target;
        const video_on = target.checked;

        return this.switchVideoEnabled(video_on);
    }

    async switchVideoEnabled(video_on) {
        const { videoInputDevices, userVideoStream } = this._store.getState().devices;

        if (!video_on && userVideoStream !== null) {
            if (userVideoStream) {
                userVideoStream.getTracks().forEach((track) => {
                    track.stop();
                });

                this._store.dispatch(devicesActions.setDevicesData({
                    userVideoStream: null
                }));
            }

            this.video.srcObject = null;
            this.video.height = "0";
        }

        if (video_on) {
            let approved = videoInputDevices.length > 0;
            if (videoInputDevices.length === 0) {
                // ask for video permission
                approved = await navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((stream) => {
                        this.#streams.push(stream);
                        stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                        return true;
                    })
                    .catch((err) => {
                        this.reportError(err.message);
                        return false;
                    });
            }
            if (approved) {
                this._store.dispatch(devicesActions.setVideoEnabled(video_on));
                this.init();
            }
        } else {
            if (videoInputDevices.length > 0) {
                this._store.dispatch(devicesActions.setVideoEnabled(video_on));
                this.init();
            }
        }
    }

    getDefaultOutputAudioDeviceId(selectedDeviceId) {
        const { audioOutputDevices } = this._store.getState().devices;

        let retVal = selectedDeviceId ? selectedDeviceId : '';

        if (selectedDeviceId && selectedDeviceId === 'default' && audioOutputDevices && audioOutputDevices.length) {
            try {
                let selectedDevice = audioOutputDevices.filter((item) => item && item.deviceId === selectedDeviceId)[0];

                retVal = audioOutputDevices.filter((item) => item && item.deviceId && item.deviceId !== 'default' && item.groupId === selectedDevice.groupId)[0].deviceId;
            } catch (error) {
                console.error('getDefaultOutputAudioDeviceId:ERROR', error);
            }
        }

        return retVal;
    }

    createMiddleware() {
        return ({ dispatch, getState }) => (next) => (action) => {
            let state = getState();
            let res = next(action);
            switch (action.type) {
                case devicesActions.SET_VIDEO_ENABLED_ACTION: {
                    const data = action.payload;
                    if (data) {
                        Cookies.set('videoEnabled', data.videoEnabled, default_cookies_param);
                    }
                    break;
                }
                case devicesActions.SET_AUDIO_ENABLED_ACTION: {
                    const data = action.payload;
                    if (data) {
                        Cookies.set('audioEnabled', data.audioEnabled, default_cookies_param);
                    }
                    break;
                }
                case devicesActions.SET_AUDIO_INPUT_DEVICE_ACTION: {
                    const data = action.payload;
                    if (data && data.audioInputDevice) {
                        Cookies.setDevice('input', data.audioInputDevice, default_cookies_param);
                    }
                    break;
                }
                case devicesActions.SET_AUDIO_OUTPUT_DEVICE_ACTION: {
                    const data = action.payload;
                    if (data && data.audioOutputDevice) {
                        Cookies.setDevice('output', data.audioOutputDevice, default_cookies_param);
                    }
                    break;
                }
                case devicesActions.SET_VIDEO_INPUT_DEVICE_ACTION: {
                    const data = action.payload;
                    if (data && data.videoInputDevice) {
                        Cookies.setDevice('camera', data.videoInputDevice, default_cookies_param);
                    }
                    break;
                }
            }
            return res;
        };
    }
}

export default new DeviceManager();