import {
    SHOW_LOW_BANDWIDTH_MODE_ACTION,
    SHOW_KNOCKS_ACTION,
    SHOW_BROADCAST_ACTION,
    SHOW_ADD_GUEST_ACTION,
    SHOW_COPY_GUEST_ACTION,
    CLEAR_ROOM_ACTION,
    DISPLAY_MESSAGE_ACTION,
    HIDE_MESSAGE_ACTION,
    SET_STREAMING_ACTION,
    SET_STREAMING_COUNTER_ACTION,
    SET_AUDIENCE_VIEW_ACTION,
    SET_APPROVED_ENTRY_ACTION,
    SET_MODAL_EVENT_OVERRUN_ACTION,
    SET_GOING_OUT_ACTION,
    SET_USER_INFO_ACTION,
    SET_EVENT_ITEM_ACTION,
    SHOW_SELF_VIEW_ACTION
} from '../actions/room_actions';

const initialState = {
    eventItem: null,
    userInfo: null,
    showKnocks: false,
    showAddGuest: false,
    showCopyGuest: false,
    roomMessage: {
        displayRoomMessage: false,
        timer: 3000,
        message: null,
        type: null
    },
    streaming: false,
    streamed: false,
    recording: null,
    audienceView: false,
    approvedEntry: null,
    modalEventOverrun: false,
    goingOut: false,
    showBroadcast: false,
    streamingCounter: false,
    filePresentation: {},
    selfView: true,
    lowBandwidthMode: false
}

const room = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOW_BANDWIDTH_MODE_ACTION:
        case SHOW_KNOCKS_ACTION:
        case SHOW_SELF_VIEW_ACTION:
        case SHOW_BROADCAST_ACTION:
        case SET_AUDIENCE_VIEW_ACTION:
        case SET_APPROVED_ENTRY_ACTION:
        case SHOW_ADD_GUEST_ACTION:
        case SHOW_COPY_GUEST_ACTION: {
            return Object.assign({}, state, action.payload);
        }
        case CLEAR_ROOM_ACTION: {
            return Object.assign({}, initialState);
        }
        case DISPLAY_MESSAGE_ACTION: {
            let messageState = {
                displayRoomMessage: true,
                message: action.payload.message,
                timer: action.payload.timer,
                type: action.payload.type
            }
            return Object.assign({}, state, { roomMessage: messageState });
        }
        case HIDE_MESSAGE_ACTION: {
            let messageState = {
                displayRoomMessage: false,
                timer: 3000,
                message: state.roomMessage.message,
                type: state.roomMessage.type
            }
            return Object.assign({}, state, { roomMessage: messageState });
        }
        case SET_STREAMING_ACTION: {
            try {
                delete action.payload["id"];
            } catch (error) {
                console.error('Error with delete id');
            }
            return Object.assign({}, state, action.payload);
        }
        case SET_STREAMING_COUNTER_ACTION: {
            return Object.assign({}, state, { streamingCounter: action.payload.streamingCounter });
        }
        case SET_MODAL_EVENT_OVERRUN_ACTION: {
            return Object.assign({}, state, { modalEventOverrun: action.payload.modalEventOverrun });
        }
        case SET_GOING_OUT_ACTION: {
            return Object.assign({}, state, { goingOut: action.payload.goingOut });
        }
        case SET_USER_INFO_ACTION: {
            return Object.assign({}, state, { userInfo: action.payload.userInfo });
        }
        case SET_EVENT_ITEM_ACTION: {
            return Object.assign({}, state, { eventItem: action.payload.eventItem });
        }
        default:
            return state;
    }
};

export default room;