import React, { Component } from 'react';
import classNames from 'classnames';
import LayoutTiles from './LayoutView/LayoutTiles';
import VideoBox from '../Common/VideoBox';
import VideoPresentation from '../Conference/VideoPresentation';

class LayoutView extends Component {
    constructor(props) {
        super(props);
    }

    getBanner() {
        const { layout, banner } = this.props;

        let retVal = null;

        if (banner && banner.img) {
            switch (layout) {
                case 'speaker':
                case 'ad-participants':
                    retVal = banner;
                    break;
                default:
                    break;
            }
        }

        return retVal;
    }

    getPipStream() {
        const { screenShare, videoPresentation, getActiveSpeaker } = this.props;

        let stream = null;

        if (screenShare && !videoPresentation && getActiveSpeaker && getActiveSpeaker()) {
            stream = getActiveSpeaker();
        }

        return stream
    }

    getSpeakerStream() {
        const { videoPresentation, isVideoPresentation, eventItem, screenShare, videoDuration, videoPlaying, activeSpeakers, isListener,
            getTilesNumber, getTilesVideo, videoTS, videoURL, layout, labels, onOrientationChanged, getActiveSpeaker } = this.props;

        if (videoPresentation && !isListener) {
            return (
                <VideoPresentation
                    isVideoPresentation={isVideoPresentation}
                    eventItem={eventItem}
                    videoPresentation={videoPresentation}
                    videoDuration={videoDuration}
                    videoURL={videoURL}
                    videoPlaying={videoPlaying}
                    videoTS={videoTS}
                />
            )
        } else
            if (screenShare) {
                return (
                    <LayoutTiles
                        layout={layout}
                        labels={labels}
                        getTilesNumber={getTilesNumber}
                        getTilesVideo={getTilesVideo}
                        onOrientationChanged={onOrientationChanged}
                        screenShare={screenShare}
                        disableTrackHandler
                    />
                )
            } else if (activeSpeakers && layout && layout === 'speaker') {
                return (
                    <div className={classNames('active-speaker', { 'speakers': this.checkShowSpeakers() }, { 'columns-2': this.ifTwoColumns() }, { 'columns-3': this.ifThreeColumns() })}>
                        {getActiveSpeaker && getActiveSpeaker() ?
                            <VideoBox
                                key={getActiveSpeaker().id}
                                className='speaker'
                                source={getActiveSpeaker().stream}
                                track={getActiveSpeaker().track}
                                picture={getActiveSpeaker().avatar}
                                autoPlay={true}
                                muted={true}
                                loop={true}
                                name={getActiveSpeaker().name}
                                labels={labels}
                                loading
                                speaking={getActiveSpeaker().speaking}
                                onOrientationChanged={onOrientationChanged}
                            />
                            : null
                        }
                    </div>
                )
            } else if (activeSpeakers) {
                return (
                    <LayoutTiles
                        layout={layout}
                        labels={labels}
                        getTilesNumber={getTilesNumber}
                        getTilesVideo={getTilesVideo}
                        onOrientationChanged={onOrientationChanged}
                        disableTrackHandler={isListener}
                    />
                )
            } else return <div />


    }

    checkShowSpeakers() {
        const { layout, getTilesVideoWithoutActiveSpeaker, screenShare, videoPresentation } = this.props;

        let retVal = false;

        if (layout && layout === 'speaker' && !screenShare && !videoPresentation && getTilesVideoWithoutActiveSpeaker && getTilesVideoWithoutActiveSpeaker().length) {
            retVal = true;
        }

        return retVal;
    }

    ifTwoColumns() {
        const { getTilesVideoWithoutActiveSpeaker } = this.props;

        let retVal = false;

        if (getTilesVideoWithoutActiveSpeaker && getTilesVideoWithoutActiveSpeaker().length && ((getTilesVideoWithoutActiveSpeaker().length >= 6 && getTilesVideoWithoutActiveSpeaker().length <= 12 && !this.getBanner()) || (getTilesVideoWithoutActiveSpeaker().length >= 5 && getTilesVideoWithoutActiveSpeaker().length <= 10 && this.getBanner()))) {
            retVal = true;
        }

        return retVal;
    }

    ifThreeColumns() {
        const { getTilesVideoWithoutActiveSpeaker } = this.props;

        let retVal = false;

        if (getTilesVideoWithoutActiveSpeaker && getTilesVideoWithoutActiveSpeaker().length && ((getTilesVideoWithoutActiveSpeaker().length >= 13 && getTilesVideoWithoutActiveSpeaker().length <= 18 && !this.getBanner()) || (getTilesVideoWithoutActiveSpeaker().length >= 11 && getTilesVideoWithoutActiveSpeaker().length <= 15 && this.getBanner()))) {
            retVal = true;
        }

        return retVal;
    }

    getVideoList() {
        const { getTilesVideoWithoutActiveSpeaker, labels, onOrientationChanged } = this.props;

        if (this.checkShowSpeakers()) {
            return (
                <div className={classNames('speaker-list', { 'no-banner': !this.getBanner() }, { 'columns-2': this.ifTwoColumns() }, { 'columns-3': this.ifThreeColumns() })}>
                    {getTilesVideoWithoutActiveSpeaker().map((item, index) => {
                        return (
                            <div className='speaker-item' key={index}>
                                <VideoBox
                                    className='item'
                                    source={item.stream}
                                    track={item.track}
                                    picture={item.avatar}
                                    autoPlay={true}
                                    muted={true}
                                    loop={true}
                                    name={item.name}
                                    labels={labels}
                                    speaking={item.speaking}
                                    onOrientationChanged={onOrientationChanged}
                                />
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    getWatermark() {
        const { showWatermark, watermark } = this.props;

        if (showWatermark) {
            return (
                <img
                    src={watermark}
                    loading='lazy'
                    alt=''
                    className='watermark'
                />
            )
        }
    }

    render() {
        const { labels, onOrientationChanged } = this.props;

        return (
            <div className='layout-view-wrapper'>
                {this.getBanner() ?
                    <div
                        className='top-banner'
                        style={this.getBanner().img ? { backgroundImage: `url(${this.getBanner().img})` } : {}}
                    />
                    : null
                }
                <div className={classNames('layout-speaker', { 'banner': this.getBanner() })}>
                    {this.getVideoList()}
                    {this.getSpeakerStream()}
                    {this.getWatermark()}
                    {this.getPipStream() ?
                        <div className='layout-pip'>
                            <VideoBox
                                key={this.getPipStream().id}
                                className='video-pip'
                                source={this.getPipStream().stream}
                                track={this.getPipStream().track}
                                picture={this.getPipStream().avatar}
                                autoPlay
                                muted
                                loop
                                name={this.getPipStream().name}
                                labels={labels}
                                speaking={this.getPipStream().speaking}
                                onOrientationChanged={onOrientationChanged}
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }
}

export default LayoutView;
