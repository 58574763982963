import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from "prop-types";
import fanoutClient from '../../../utils/FanoutClient';
import ClickOutside from '../../Widget/ClickOutside';
import Hangup from "../../../../resources/assets/Exit.svg";
import * as roomActions from "../../../actions/room_actions";

class HangUpButtonBottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      knockRid: null,
      notGrantedId: null,
      continueModal: false
    }

    this.clearData = this.clearData.bind(this);
    this.extendCall = this.extendCall.bind(this);
    this.letEventContinue = this.letEventContinue.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
  }

  componentDidMount() {
    const { knocks, getCallState } = this.props;

    if (Object.keys(knocks).length) {
      let callState = getCallState(), id = null, notGrantedId = null;

      Object.keys(knocks).map((key) => {
        if (knocks[key] && callState && knocks[key].uid === callState.uid) {
          if(knocks[key].granted) {
            id = knocks[key].rid;
          } else {
            notGrantedId = knocks[key].rid;
          }
        }
        return key;
      });

      this.setState({
        knockRid: id,
        notGrantedId: notGrantedId
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { modal } = this.state;
    const { knocks, getCallState, goingOut, hideMenu } = this.props;

    let callState = getCallState(), id = null, notGrantedId = null;
    if (Object.keys(knocks).length !== Object.keys(prevProps.knocks).length) {
      if (Object.keys(knocks).length) {
        Object.keys(knocks).map((key) => {
          if (knocks[key] && callState && knocks[key].uid === callState.uid) {
            if(knocks[key].granted) {
              id = knocks[key].rid;
            } else {
              notGrantedId = knocks[key].rid;
            }
          }
          return key;
        });
      }

      this.setState({
        knockRid: id,
        notGrantedId: notGrantedId
      });
    }

    // if (goingOut && goingOut !== prevProps.goingOut) {
    //   this.clearData()
    // }

    if (hideMenu && !modal && prevState.modal !== modal) {
      hideMenu();
    }
  }

  clearData() {
    const { knockRid, notGrantedId } = this.state;
    const { leave } = this.props;

    if (leave) {
      leave();
    }

    if (knockRid) {
      this.handleRevokeUser(knockRid);
    }

    if (notGrantedId) {
      this.handleRevokeUser(notGrantedId);
    }
  }

  handleRevokeUser(rid) {
    const { getCallState } = this.props;
    if (rid && getCallState) {
      fanoutClient.sendKnockRevoked(rid, getCallState());
    }
  }

  extendCall() {
    const { eventId } = this.props;

    let timestamp = moment(new Date()).add(1, 'hours').valueOf(), alias = eventId ? eventId : null;
    fanoutClient.sendExtendCall(timestamp, alias);
  }

  letEventContinue() {
    if (this.getHostLength() > 1) {
      this.reloadPage();
    } else {
      this.setState({ continueModal: true });
    }
  }

  reloadPage() {
    const { displayMessage } = this.props;

    this.setState({
      modal: false,
      continueModal: false
    }, () => {
      if (displayMessage) {
        displayMessage('Leaving the event, now returning to event page', 5000);
      }

      setTimeout(() => {
        window.location.reload();
      }, 2000);

    });
  }

  getHostLength() {
    const { hosts } = this.props;
    let retVal = 0;

    if (hosts && Object.entries(hosts).length) {
      Object.entries(hosts).map(([id, participant], i) => {
        if (participant && participant.role === 'presenter') {
          ++retVal;
        }

        return id;
      })
    }

    return retVal;
  }

  render() {
    const { modal, knockRid, continueModal } = this.state;
    const { modalEventOverrun, role, streamed } = this.props;

    return (
      <li className="hangup-bottom-bar">
        <a
          data-tip
          data-for="leave"
          title='Exit Stream'
          onClick={() => this.setState({ modal: !modal })}
        >
          <img src={Hangup} />
        </a>
        {continueModal ?
          <div className='hangup-modal-wrapper'>
            <ClickOutside
              className='hangup-modal'
              clickOutside={() => this.setState({ continueModal: false })}
            >
              <div className='hangup-text continue'>You are the only host remaining in the event. When you leave, if the event is without a host for 5 minutes, the event will end automatically for all participants.</div>
              {role && ((role === 'presenter') || (role === 'moderator')) && streamed ?
                <button
                  className='btn hangup-end'
                  onClick={this.reloadPage}
                >
                  Ok
                </button>
                : null}
              <button
                className='btn hangup-cancel'
                onClick={() => this.setState({ continueModal: false })}
              >
                Cancel
              </button>
            </ClickOutside>
          </div>
          : modal ?
            <div className='hangup-modal-wrapper'>
              <ClickOutside
                className='hangup-modal'
                clickOutside={() => this.setState({ modal: false })}
              >
                <div className='hangup-title'>{role && ((role === 'presenter') || (role === 'moderator')) && streamed ? 'Leave and..' : 'Confirm Exit'}</div>
                {knockRid ?
                  <button
                    className='btn hangup-exit'
                    onClick={() => {
                      this.handleRevokeUser(knockRid);
                      this.setState({ modal: false })
                    }}
                  >
                    Move To Audience
                  </button>
                  :
                  null}
                <button
                  className='btn hangup-end'
                  onClick={this.clearData}
                >
                  {role && ((role === 'presenter') || (role === 'moderator')) && streamed ? 'End event for everyone' : 'Exit Stream'}
                </button>
                {role && ((role === 'presenter') || (role === 'moderator')) && streamed ?
                  <button
                    className='btn hangup-end'
                    onClick={this.letEventContinue}
                  >
                    Let event continue
                  </button>
                  : null}
                <button
                  className='btn hangup-cancel'
                  onClick={() => this.setState({ modal: false })}
                >
                  Cancel
                </button>
              </ClickOutside>
            </div>
            : null}
        {/* {modalEventOverrun ?
          <div className='overlay-modal-wrapper'>
            <div className='overlay-modal big-modal'>
              <div className='overlay-title'>Event Overrun</div>
              <div className='overlay-text'>This event has significantly run over the original time. Do you want the event to continue?</div>
              <button
                className='btn overruns-yes'
                onClick={this.extendCall}
              >
                Yes
              </button>
              <button
                className='btn overruns-no'
                onClick={this.clearData}
              >
                No
              </button>
            </div>
          </div>
          : null} */}
      </li>
    );
  }
}

HangUpButtonBottomBar.propTypes = {
  leave: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    knocks: state.knocks,
    hosts: state.hosts,
    modalEventOverrun: state.room.modalEventOverrun,
    goingOut: state.room.goingOut,
    streamed: state.room.streamed
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    displayMessage: (message, timer) => {
      dispatch(roomActions.displayMessage({ message: message, timer: timer }));
    }
  };
};

const HangUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HangUpButtonBottomBar);

export default HangUpContainer;
