import React, { Component } from "react";
import PropTypes from "prop-types";
import MuteOn from "../../../../../resources/assets/Mute-Active.svg";
import MuteOff from "../../../../../resources/assets/Mute.svg";
import { isMobile } from "../../../../utils/text";

class MicrophoneButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: isMobile(),
            hover: false
        };
    }

    render() {
        const { isMuted, toggle, isBottomBar } = this.props;
        const { hover, isMobile } = this.state;
        return (
            <li
                className={isMuted ? "active" : ""}
                onMouseEnter={() => {
                    !isMobile && this.setState({ hover: true });
                }}
                onMouseLeave={() => {
                    !isMobile && this.setState({ hover: false });
                }}
            >
                <a
                    data-tip
                    data-for="toggle-mute"
                    className={" " + (isMuted ? "on" : "off")}
                    title={isMuted ? "Unmute" : "Mute"}
                    onClick={() => toggle()}
                >
                    <img src={isMuted ? MuteOn : MuteOff} />
                    {isBottomBar && (
                        <div>
                            <span>{isMuted ? "Mic off" : "Mic on"}</span>
                        </div>
                    )}
                </a>
            </li>
        );
    }
}

MicrophoneButton.propTypes = {
    toggle: PropTypes.func.isRequired,
    isMuted: PropTypes.bool.isRequired,
    isBottomBar: PropTypes.bool.isRequired
};

export default MicrophoneButton;
