import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import Button from '../Widget/Button';
import downArrowIcon from "../../../resources/assets/header-arrow.svg";
import tikTokIcon from "../../../resources/assets/tiktok.svg";
import userPlaceholder from "../../../resources/assets/user-placeholder.png";
import * as firebaseActions from "../../actions/firebase_actions";
import {
  signupCampaignPageLink,
  dudaLink,
  facebookLink,
  twitterLink,
  instagramLink,
  youtubeLink,
  linkedInLink,
  tikTokLink,
  showBecomeAHost
} from '../../utils/text';

class ProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: false
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChangeProfileList = this.handleChangeProfileList.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleChangeProfileList() {
    this.setState({
      list: !this.state.list
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { list } = this.state;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && list) {
      this.setState({
        list: false
      });
    }
  }

  handleLogOut() {
    const { logOut } = this.props;
    if (logOut) {
      logOut();
    }
  }

  render() {
    const { list } = this.state;
    const { user, activeTab, broadcasterUser, mobile, auth, roles, history } = this.props;

    let avatar = user ? (user.profile_picture2 ?
      user.profile_picture2 : (user.profile_picture ?
        user.profile_picture : userPlaceholder)) : userPlaceholder;

    return (
      <div ref={this.setWrapperRef}>
        <Button
          type='text'
          className='btn-profile'
          onClick={this.handleChangeProfileList}
        >
          <img
            src={avatar}
            loading="lazy"
            className={classNames('user-icon', { 'mobile': mobile })}
          />
          <p className='user-text'>{user ? user.username : ''}</p>
          <img className={classNames('user-arrow', { 'rotate': list })} src={downArrowIcon} loading='lazy' />
        </Button>
        {list ?
          <div className="profile-list">
            {user ?
              <Link
                to='/mystuff'
                className={classNames('profile-item', { 'active': activeTab === '/mystuff' })}
                onClick={this.handleChangeProfileList}
              >
                My Stuff
              </Link>
              : null
            }
            {broadcasterUser && user ?
              <Link
                to={`/profile/${user && user.uid ? user.uid : null}`}
                className={classNames('profile-item', { 'active': activeTab === `/profile/${user && user.uid ? user.uid : null}` })}
                onClick={this.handleChangeProfileList}
              >
                My Profile
              </Link>
              : null
            }
            {broadcasterUser &&
              <Link
                to='/calendar'
                className={classNames('profile-item', { 'active': activeTab === '/calendar' })}
                onClick={this.handleChangeProfileList}
              >
                Event Scheduler
              </Link>
            }
            {broadcasterUser && history && history.location && history.location.pathname && history.location.pathname.indexOf('/calendar') !== -1 &&
              <a
                href={`${dudaLink}/schedule-an-event`}
                className='profile-item htce'
              >
                How To Create Event
              </a>
            }
            {showBecomeAHost && (!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
              <a
                href={signupCampaignPageLink}
                className={classNames('profile-item', { 'active': activeTab === '/signup' })}
                onClick={this.handleChangeProfileList}
              >
                Become A Host
              </a> : null
            }
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
              <a
                href={`${dudaLink}/why-no-filter`}
                className="profile-item"
              >
                Why No Filter?
              </a>
              : null
            }
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
              <Link
                to='/browse?b=all'
                className={classNames('profile-item', { 'active': activeTab === '/browse' })}
                onClick={this.handleChangeProfileList}
              >
                Browse Events
            </Link>
              : null
            }
            <a
              href={`${dudaLink}/help`}
              className="profile-item"
            >
              Help
            </a>
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) && auth && roles && roles.administrator ?
              <Link
                to='/calendar?gm=1'
                className='profile-item'
                onClick={this.handleChangeProfileList}
              >
                Streaming
            </Link>
              : null
            }
            <div className="profile-list-hr" />
            <div
              className="profile-item logout"
              onClick={() => {
                this.handleLogOut();
                this.handleChangeProfileList();
              }}
            >
              Log Out
            </div>
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
              <div className="profile-list-hr" />
              : null
            }
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
              <div className="profile-item profile-item--small">
                <a
                  href={`${dudaLink}/about`}
                  className="links-item"
                >
                  About us
              </a>
              </div>
              : null
            }
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
              <div className="profile-item profile-item--small">
                <a href={`${dudaLink}/help#ContactUs`}>
                  Contact us
              </a>
              </div>
              : null
            }
            {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
              <div className="social-media-links">
                {facebookLink ?
                  <a
                    className="facebook-icon fa fa-facebook"
                    href={facebookLink}
                    target="_blank"
                  />
                  : null
                }
                {twitterLink ?
                  <a
                    className="twitter-icon fa fa-twitter"
                    href={twitterLink}
                    target="_blank"
                  />
                  : null
                }
                {instagramLink ?
                  <a
                    className="instagram-icon fa fa-instagram"
                    href={instagramLink}
                    target="_blank"
                  />
                  : null
                }
                {youtubeLink ?
                  <a
                    className="youtube-icon fa fa-youtube-play"
                    href={youtubeLink}
                    target="_blank"
                  />
                  : null
                }
                {linkedInLink ?
                  <a
                    className="linkedIn-icon fa fa-linkedin-square"
                    href={linkedInLink}
                    target="_blank"
                  />
                  : null
                }
                {tikTokLink ?
                  <a
                    className="tiktok-icon"
                    href={tikTokLink}
                    target="_blank"
                  >
                    <img src={tikTokIcon} loading='lazy' />
                  </a>
                  : null
                }
              </div>
              : null
            }
          </div>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.firebase.user,
    broadcasterUser: state.firebase.roles && state.firebase.roles.broadcaster ? true : false,
    mobile: state.app.user_agent_info.platform.type === 'mobile',
    auth: state.firebase.auth,
    roles: state.firebase.roles
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => {
      dispatch(firebaseActions.logOut());
    }
  };
};

const ProfileListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileList);

export default ProfileListContainer;
