import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MicrophoneButton from './Buttons/MicrophoneButton';
import VideoButton from './Buttons/VideoButton';
import KnocksContainer from '../Voxeet/KnocksButtonBottomBar';
import KnockButtonBottomBar from '../Voxeet/KnockButtonBottomBar';
import AttendeesListButton from '../Voxeet/AttendeesListButton';
import TipButtonBottomBar from '../Voxeet/TipButtonBottomBar';
import BroadcastButtonBottomBar from '../Voxeet/BroadcastButtonBottomBar';
import HangUpButtonBottomBar from '../Voxeet/HangUpButtonBottomBar';
import InboundVideoButtonBottomBar from '../Common/InboundVideoButtonBottomBar';
import ScreenShareButton from './Buttons/ScreenShareButton';
import SettingsButton from './Buttons/SettingsButton';
import { isMobile } from '../../../utils/text';

class ConferenceButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBar: true
        }

        this.onShowMenu = this.onShowMenu.bind(this);
        this.onHideMenu = this.onHideMenu.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    componentDidMount() {
        const { isListener } = this.props;

        if (isListener) {
            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.barTimeout = setTimeout(() => {
                const { displayAttendeesList, showTip } = this.props;
                if (!displayAttendeesList && !showTip) {
                    this.setState({
                        showBar: false
                    });
                }
            }, 3000);
        }
    }

    componentDidUpdate(prevProps) {
        const { isListener, displayAttendeesList, showTip } = this.props;

        if (prevProps.isListener !== isListener) {
            if (isListener) {
                if (this.barTimeout) {
                    clearTimeout(this.barTimeout);
                }

                this.barTimeout = setTimeout(() => {
                    const { displayAttendeesList, showTip } = this.props;
                    if (!displayAttendeesList && !showTip) {
                        this.setState({
                            showBar: false
                        });
                    }
                }, 3000);
            } else {
                if (this.barTimeout) {
                    clearTimeout(this.barTimeout);
                }

                this.setState({
                    showBar: true
                });
            }
        }

        if (!displayAttendeesList && !showTip && ((prevProps.displayAttendeesList !== displayAttendeesList) || (prevProps.showTip !== showTip))) {
            this.onHideMenu();
        }
    }

    onShowMenu() {
        const { isListener } = this.props;

        if (isListener) {
            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.setState({
                showBar: true
            });
        }
    }

    onHideMenu() {
        const { isListener } = this.props;

        if (isListener) {
            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.barTimeout = setTimeout(() => {
                const { displayAttendeesList, showTip } = this.props;
                if (!displayAttendeesList && !showTip) {
                    this.setState({
                        showBar: false
                    });
                }
            }, 2000);
        }
    }

    onMouseMove() {
        const { isListener } = this.props;

        if (isListener) {
            this.setState({
                showBar: true
            });

            if (this.barTimeout) {
                clearTimeout(this.barTimeout);
            }

            this.barTimeout = setTimeout(() => {
                const { displayAttendeesList, showTip } = this.props;
                if (!displayAttendeesList && !showTip) {
                    this.setState({
                        showBar: false
                    });
                }
            }, 3000);
        }
    }

    render() {
        const {
            showBar
        } = this.state;
        const {
            isMuted,
            isAdmin,
            isListener,
            handleMuteAudio,
            role,
            streaming,
            videoEnabled,
            handleMuteVideo,
            getCallState,
            toggleList,
            displayAttendeesList,
            toggleSettings,
            attendeesSettingsOpened,
            newMessage,
            endConference,
            eventId,
            eventItem,
            knockId,
            isAnonymous,
            toggleScreenShare,
            toggleVideoPresentation,
            selfScreenShare,
            selfVideoPresentation,
            muteRemoteVideosForMe
        } = this.props;
        return (
            <Fragment>
                {!showBar ?
                    <div
                        className='hide-button-overlay'
                        onMouseMove={this.onMouseMove}
                    />
                    : null
                }
                <div
                    className={classNames("vxt-bottom-bar", { 'show': showBar }, { 'hide': !showBar }, { 'transparent': isListener })}
                    onMouseOver={this.onShowMenu}
                    onMouseLeave={this.onHideMenu}
                >
                    <div className="vxt-bottom-bar-actions">
                        <ul className="controls-left">
                            {!isListener && (
                                <MicrophoneButton
                                    isMuted={isMuted}
                                    toggle={handleMuteAudio}
                                    isBottomBar={true}
                                />)}
                            {(role && (role !== 'moderator' || (role === 'moderator' && !streaming))) && !isListener && (
                                <VideoButton
                                    videoEnabled={videoEnabled}
                                    toggle={handleMuteVideo}
                                    isBottomBar={true}
                                />
                            )}
                            {isAdmin && (
                                <KnocksContainer
                                    getCallState={getCallState}
                                />
                            )}
                            {/* {isListener && (
                            <InboundVideoButtonBottomBar
                                muteRemoteVideosForMe={muteRemoteVideosForMe}
                            />
                        )} */}
                        </ul>
                        <ul className="controls-center">
                            {!isListener && !isMobile() && (
                                <ScreenShareButton
                                    shareActions={["screenshare", "filepresentation", "videopresentation"]}
                                    isBottomBar={true}
                                    screenShareEnabled={selfScreenShare}
                                    filePresentationEnabled={false}
                                    videoPresentationEnabled={selfVideoPresentation}
                                    currentUserScreenShare={selfScreenShare}
                                    currentUserFilePresentation={false}
                                    currentUserVideoPresentation={selfVideoPresentation}
                                    convertFilePresentation={(e) => console.log('convert file presentation', e)}
                                    toggle={toggleScreenShare}
                                    toggleVideoPresentation={toggleVideoPresentation}
                                />)
                            }
                            <AttendeesListButton
                                toggle={toggleList}
                                isBottomBar={true}
                                isAdmin={isAdmin}
                                role={role}
                                isOpen={displayAttendeesList}
                                newMessage={newMessage}
                            />
                        </ul>
                        <ul className="controls-right">
                            {!isAdmin && isListener && eventId && (
                                <KnockButtonBottomBar
                                    knockId={knockId}
                                    conferenceAlias={eventId}
                                    getCallState={getCallState}
                                    isAnonymous={isAnonymous}
                                    hideMenu={this.onHideMenu}
                                />
                            )}
                            {eventItem && eventItem.acceptTips && isListener && (
                                <TipButtonBottomBar
                                    conferenceAlias={eventId}
                                    item={eventItem}
                                    isAnonymous={isAnonymous}
                                    hideMenu={this.onHideMenu}
                                />
                            )}
                            {!isListener && (
                                <SettingsButton
                                    attendeesSettingsOpened={attendeesSettingsOpened}
                                    toggle={toggleSettings}
                                    isBottomBar={true}
                                    isListener={isListener}
                                />
                            )}
                            {isAdmin && !isListener && (
                                <BroadcastButtonBottomBar
                                    eventItem={eventItem}
                                    getCallState={getCallState}
                                    role={role}
                                // hideModerator={() => this.hideModerator()}
                                // prevStateModerator={() => this.prevStateModerator()}
                                />
                            )}
                            <HangUpButtonBottomBar
                                leave={endConference}
                                getCallState={getCallState}
                                eventId={eventId}
                                role={role}
                                hideMenu={this.onHideMenu}
                            />
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        streaming: state.room.streaming,
        newMessage: state.chat.newMessage,
        showTip: state.broadcast.showTip
    };
};

const ConferenceButtonsContainer = connect(
    mapStateToProps,
    null // mapDispatchToProps
)(ConferenceButtons);

export default ConferenceButtonsContainer;