import React, { Component } from "react";
import PropTypes from "prop-types";
import CameraOn from "../../../../../resources/assets/Camera-Active.svg";
import CameraOff from "../../../../../resources/assets/Camera.svg";
import { isMobile } from "../../../../utils/text";

class VideoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: isMobile(),
      hover: false
    };
  }

  render() {
    const { videoEnabled, toggle, isBottomBar } = this.props;
    const { hover, isMobile } = this.state;

    return (
      <li
        className={videoEnabled ? "active" : ""}
        onMouseEnter={() => {
          !isMobile && this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          !isMobile && this.setState({ hover: false });
        }}
      >
        <a
          data-tip
          data-for="toggle-video"
          title={videoEnabled ? "Turn off" : "Turn on"}
          onClick={() => toggle()}
        >
          <img src={videoEnabled ? CameraOn : CameraOff} />
          {isBottomBar && (
            <div>
              <span>{videoEnabled ? "Camera on" : "Camera off"}</span>
            </div>
          )}
        </a>
      </li>
    );
  }
}

VideoButton.propTypes = {
  videoEnabled: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isBottomBar: PropTypes.bool.isRequired
};

export default VideoButton;