import React, { Component } from "react";
import { connect } from "react-redux";
import LogInMail from './LogInMail';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient';
import appleIcon from '../../../resources/assets/apple.svg';
import yahooIcon from '../../../resources/assets/yahoo.svg';
import googleIcon from '../../../resources/assets/google_logo.svg';
import mailIcon from '../../../resources/assets/mail-white.svg';
import closeIcon from '../../../resources/assets/x.svg';
import { dudaLink } from '../../utils/text';
import { strings } from '../../translations/localizedStrings';

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      pairing: false,
      error: null,
      showError: false,
      showPairing: false,
      loaderText: '',
      logInMail: false,
      terms: false,
      showTerms: false
    }
    this.setProvider = this.setProvider.bind(this);
    this.reset = this.reset.bind(this);
    this.startPairing = this.startPairing.bind(this);
    this.handleShowMailForm = this.handleShowMailForm.bind(this);
    this.handleSetTerms = this.handleSetTerms.bind(this);
  }

  componentDidMount() {
    const storageTerms = localStorage.getItem('nfnTerms') === 'true';
    this.setState({ terms: storageTerms });
    this.setState({ showTerms: !storageTerms });
  };

  componentDidUpdate(prevProps, prevState) {
    const { handleShowLogIn } = this.props;

    if (this.props.auth && prevProps.auth !== this.props.auth && handleShowLogIn) {
      handleShowLogIn();
    }
  }

  setProvider(provider) {
    if (provider) {
      this.setState({
        processing: true,
        showError: false,
        showPairing: false,
        loaderText: ''
      }, () => {
        firebaseClient.logIn(provider).catch(error => {
          if (error.code === 'auth/account-exists-with-different-credential') {
            this.setState({
              showPairing: true,
              showError: false,
              processing: false,
              error,
              loaderText: ''
            });
          } else if (error.code === 'auth/popup-closed-by-user') {
            this.setState({
              showPairing: false,
              showError: false,
              processing: true,
              error,
              loaderText: 'Login cancelled'
            }, () => {
              setTimeout(() => {
                this.reset();
              }, 2000);
            });
          } else {
            this.setState({
              showError: true,
              showPairing: false,
              processing: false,
              error,
              loaderText: ''
            });
          }
        });
      });
    }
  }

  startPairing() {
    if (this.state.error) {
      this.setState({
        processing: true,
        showError: false,
        showPairing: false,
        loaderText: ''
      }, () => {
        firebaseClient.pairAccounts(this.state.error).catch(error => {
          this.setState({
            showError: true,
            showPairing: false,
            processing: false,
            error,
            loaderText: ''
          });
        });
      });
    } else {
      console.error('Could not find pending credentials');
    }
  }

  handleShowMailForm() {
    this.setState({
      logInMail: true
    });
  }

  handleSetTerms(value) {
    this.setState({ terms: value });
    localStorage.setItem('nfnTerms', value);
  }

  reset() {
    this.setState({
      showError: false,
      logInMail: false,
      showPairing: false,
      processing: false,
      error: null,
      loaderText: ''
    });
  }

  render() {
    const { processing, logInMail, showError, showPairing, loaderText, terms, showTerms } = this.state;
    const { handleShowLogIn, createAccount, logInSignup } = this.props;

    return (
      <div className="log-in-wrapper">
        {logInMail ?
          <LogInMail
            close={this.reset}
          />
          : showError ?
            <div className='log-in-div'>
              <div className="log-in-close">
                <img
                  src={closeIcon}
                  loading='lazy'
                  aria-hidden="true"
                  onClick={handleShowLogIn}
                />
              </div>
              <p className='log-in-title'>Error</p>
              <p className='log-in-text error'>Some error occurred</p>
              <div className="choose-buttons">
                <Button
                  type='border'
                  className='btn-choose'
                  onClick={this.reset}
                >
                  <p>Cancel</p>
                </Button>
              </div>
            </div> :
            processing ?
              <div className='log-in-div'>
                <Loader
                  text={loaderText ? loaderText : "Loading"}
                  login={true}
                  dots={!loaderText}
                />
              </div> :
              showPairing ?
                <div className='log-in-div'>
                  <div className="log-in-close">
                    <img
                      src={closeIcon}
                      loading='lazy'
                      aria-hidden="true"
                      onClick={handleShowLogIn}
                    />
                  </div>
                  <p className='log-in-title'>Pairing</p>
                  <p className='log-in-text'>
                    You have already signed up with a different auth provider for that email.
                    <br />
                    Would you like to pair accounts?
                  </p>
                  <div className="choose-buttons">
                    <Button
                      type='default'
                      className='btn-choose'
                      onClick={this.startPairing}
                    >
                      <p>Pair</p>
                    </Button>
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={this.reset}
                    >
                      <p>Cancel</p>
                    </Button>
                  </div>
                </div>
                :
                <div className="log-in-div">
                  <div className="log-in-close">
                    <img
                      src={closeIcon}
                      loading='lazy'
                      aria-hidden="true"
                      onClick={handleShowLogIn}
                    />
                  </div>
                  {createAccount || logInSignup ?
                    <p className="log-in-title createAccount">
                      {createAccount ?
                        "Verify Your Email to Create Account"
                        :
                        `Use Your Email to Create a ${strings.appName} Account`
                      }
                    </p>
                    :
                    <p className="log-in-title sm-title">
                      Please login to access events
                  </p>
                  }
                  {showTerms ?
                    <div className='accept-terms'>
                      <input
                        type='checkbox'
                        checked={terms}
                        disabled={processing}
                        onChange={(e) => this.handleSetTerms(e.target.checked)}

                      />
                      {process.env.build && process.env.build === 'nfl-aa' ?
                        <p>
                          {"Please tick the box to agree to the "} <br /> {"NFL Alumni Media "}
                          <a
                            href={`${dudaLink}/terms`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Use
                      </a>
                        </p>
                        :
                        <p>
                          {"Please tick the box to agree to "} <br /> {"the No Filter "}
                          <a
                            href={`${dudaLink}/terms`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Service
                        </a>
                        </p>
                      }
                    </div>
                    : null
                  }
                  <div className="choose-buttons">
                    {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
                      <Button
                        type='border'
                        className='btn-choose'
                        onClick={() => this.setProvider("apple")}
                        disabled={processing || !terms}
                      >
                        <img
                          src={appleIcon}
                          loading='lazy'
                          className='apple-icon'
                        />
                        {createAccount || logInSignup ? 'create account with apple' : 'sign in with apple'}
                      </Button>
                      : null
                    }
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={() => this.setProvider("google")}
                      disabled={processing || !terms}
                    >
                      <img
                        src={googleIcon}
                        loading='lazy'
                        className='google-icon'
                      />
                      {createAccount || logInSignup ? 'create account with google' : 'sign in with google'}
                    </Button>
                    {((!process.env.platform) || (process.env.platform && process.env.platform !== 'mobile')) && ((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ?
                      <Button
                        type='border'
                        className='btn-choose'
                        onClick={() => this.setProvider("yahoo")}
                        disabled={processing || !terms}
                      >
                        <img
                          src={yahooIcon}
                          loading='lazy'
                          className='yahoo-icon'
                        />
                        {createAccount || logInSignup ? 'create account with yahoo' : 'sign in with yahoo'}
                      </Button>
                      : null
                    }
                    <Button
                      type='border'
                      className='btn-choose'
                      onClick={this.handleShowMailForm}
                      disabled={processing || !terms}
                    >
                      <img
                        src={mailIcon}
                        loading='lazy'
                        className='mail-icon'
                      />
                      {createAccount || logInSignup ? 'create account with mail' : 'sign in with mail'}
                    </Button>
                  </div>
                </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    createAccount: state.app.createAccount,
    logInSignup: state.app.logInSignup
  };
};

const LogInContainer = connect(
  mapStateToProps
)(LogIn);

export default LogInContainer;
